import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    connectAccount,
    getCountrySpecs,
} from '../../redux/businessAccount/detailsAction';

const getFieldType = (field) => {
    field = field.split('.').at(-1);
    switch (field) {
        case 'city':
        case 'line1':
        case 'postal_code':
        case 'state':
        case 'first_name':
        case 'last_name':
        case 'phone':
        case 'support_phone':
            return 'text';
        case 'email':
        case 'support_email':
            return 'email';
        case 'date':
            return 'date';
        default:
            return 'text';
    }
};

const formatFieldName = (field) => {
    const cleanField = field.replace(
        /^(individual\.|business_profile\.|dob\.|address\.)+/,
        ''
    );
    return cleanField.replace(/\./g, ' ').replace(/_/g, ' ');
};

const formatSortCode = (value) => {
    const digitsOnly = value.replace(/\D/g, '');

    return digitsOnly
        .replace(/(\d{2})(\d{2})(\d{0,2})/, '$1-$2-$3')
        .trim()
        .replace(/-$/, '');
};

const ConnectAccountModal = ({ formData, isModalOpen, handleClose }) => {
    const date = new Date();
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.user);
    const { isLoading } = useSelector((state) => state.businessDetails);
    const formattedDate = new Intl.DateTimeFormat('en-CA').format(date);
    const [routing_number, setRouting_number] = useState('');
    const [dob, setDob] = useState('');
    const [data, setData] = useState({
        business_type: 'individual',
        mcc: '5045',
        url: 'https://bestcookieco.com',
        ip: '172.18.80.19',
        type: 'custom',
        userId: userData?.id,
        country: 'GB',
        currency: 'gbp',
        date: formattedDate,
    });
    useEffect(() => {
        dispatch(getCountrySpecs('GB'));
    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'dob') {
            setDob(value);
            const [year, month, day] = value.split('-');
            setData((prev) => ({
                ...prev,
                day: day,
                month: month,
                year: year,
            }));
        } else if (files) {
            setData((prev) => ({
                ...prev,
                [name]: files[0],
            }));
        } else {
            setData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleConnectSubmit = async (e) => {
        e.preventDefault();

        const formDataObj = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            const cleanKey = key.replace(
                /^(individual\.|business_profile\.|individual.dob\.|address\.|tos_acceptance\.)+/,
                ''
            );

            formDataObj.append(cleanKey, value);
        });

        dispatch(
            connectAccount(formDataObj, (response) => {
                if (response?.id) {
                } else {
                    console.error('Error: No response ID found');
                }
            })
        );
    };

    const excludedFields = [
        'business_profile.mcc',
        'business_profile.url',
        'business_type',
        'individual.dob.day',
        'individual.dob.month',
        'individual.dob.year',
        'tos_acceptance.ip',
        'tos_acceptance.date',
        'external_account',
        'business_profile.support_email',
        'business_profile.support_phone',
    ];

    return (
        <div
            className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
            tabIndex="-1"
            role="dialog"
            style={{ background: 'rgba(0,0,0,0.5)' }}
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header d-flex justify-content-between">
                        <h5 className="modal-title">Connect Your Account</h5>
                        <button
                            type="button"
                            className="close"
                            onClick={handleClose}
                        >
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleConnectSubmit}>
                            {formData?.requirements
                                ?.filter(
                                    (field) => !excludedFields.includes(field)
                                )
                                .sort((a, b) => {
                                    const priorityFields = [
                                        'individual.first_name',
                                        'individual.last_name',
                                    ];
                                    if (
                                        priorityFields.includes(a) &&
                                        !priorityFields.includes(b)
                                    )
                                        return -1;
                                    if (
                                        !priorityFields.includes(a) &&
                                        priorityFields.includes(b)
                                    )
                                        return 1;
                                    return 0;
                                })
                                .map((field) => (
                                    <div className="mb-3" key={field}>
                                        <label className="form-label text-capitalize">
                                            {formatFieldName(field) === 'line1'
                                                ? 'Line 1'
                                                : formatFieldName(field)}
                                        </label>
                                        <input
                                            type={getFieldType(field)}
                                            className="form-control"
                                            name={field}
                                            onChange={handleChange}
                                            required
                                            {...(getFieldType(field) === 'date'
                                                ? {
                                                      max: new Date()
                                                          .toISOString()
                                                          .split('T')[0],
                                                  }
                                                : {})}
                                        />
                                    </div>
                                ))}
                            {formData?.requirements.includes(
                                'external_account'
                            ) && (
                                <>
                                    <div className="mb-3">
                                        <label className="form-label">
                                            Account Number{' '}
                                            <span className="text-muted">
                                                (e.g., 00012345)
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="ibn_number"
                                            value={data.ibn_number}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">
                                            Sort Code{' '}
                                            <span className="text-muted">
                                                (e.g., 10-88-00)
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="routing_number"
                                            value={routing_number}
                                            onChange={(e) =>
                                                setRouting_number(
                                                    formatSortCode(
                                                        e.target.value
                                                    )
                                                )
                                            }
                                            maxLength={8}
                                            placeholder="XX-XX-XX"
                                            required
                                        />
                                    </div>
                                </>
                            )}
                            {formData?.requirements.includes(
                                'individual.dob.day'
                            ) && (
                                <div className="mb-3">
                                    <label className="form-label">
                                        Date of Birth
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="dob"
                                        value={dob}
                                        onChange={handleChange}
                                        max={
                                            new Date(
                                                new Date().setFullYear(
                                                    new Date().getFullYear() -
                                                        18
                                                )
                                            )
                                                .toISOString()
                                                .split('T')[0]
                                        }
                                        required
                                    />
                                </div>
                            )}
                            {formData?.requirements?.includes(
                                'additional_document'
                            ) && (
                                <div className="mb-3">
                                    <label className="form-label">
                                        Identity Document
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="additional_document"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            )}
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={handleConnectSubmit}
                            className="btn btn-next"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectAccountModal;

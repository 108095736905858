import { toast } from 'react-toastify';
import { axiosInstance } from '../../repository/Repository';
import { setUser } from '../users/actionCreator';
import {
    CONNECT_ACCOUNT_PENDING,
    CONNECT_ACCOUNT_REJECTED,
    CONNECT_ACCOUNT_STATUS_PENDING,
    CONNECT_ACCOUNT_STATUS_REJECTED,
    CONNECT_ACCOUNT_STATUS_SUCCESS,
    CONNECT_ACCOUNT_SUCCESS,
    GET_AUTOMATED_SETTING_ERROR,
    GET_AUTOMATED_SETTING_START,
    GET_AUTOMATED_SETTING_SUCCESS,
    GET_CONNECT_ACCOUNT_PENDING,
    GET_CONNECT_ACCOUNT_REJECTED,
    GET_CONNECT_ACCOUNT_SUCCESS,
    GET_COUNTRY_SPEC_PENDING,
    GET_COUNTRY_SPEC_REJECTED,
    GET_COUNTRY_SPEC_SUCCESS,
    GET_DETAILS,
    GET_PENDING,
    GET_PRICING_ERROR,
    GET_PRICING_START,
    GET_PRICING_SUCCESS,
    GET_REJECTED,
    INSTA_RES,
    RESET_INSTA_FILES,
    UPDATE_AUTOMATED_SETTING_ERROR,
    UPDATE_AUTOMATED_SETTING_START,
    UPDATE_AUTOMATED_SETTING_SUCCESS,
    UPDATE_DETAILS,
    UPDATE_PENDING,
    UPDATE_REJECTED,
} from './types';

export const getDetails = () => async (dispatch) => {
    dispatch({ type: GET_PENDING });
    try {
        const response = await axiosInstance.get('/business/');
        dispatch({ type: GET_DETAILS, payload: response.data.user });
        dispatch(setUser(response?.data?.user));
        return response.data;
    } catch (error) {
        console.log('Error Fetching Business Details');
        dispatch({ type: GET_REJECTED });
        throw error;
    }
};

export const updateDetails = (formData) => async (dispatch) => {
    dispatch({ type: UPDATE_PENDING });
    try {
        const response = await axiosInstance.put('/business/update', formData);
        dispatch({ type: UPDATE_DETAILS });
        console.log('UpdateResponse', response.data);
        return response.data;
    } catch (error) {
        console.log('Error Updating Business Details');
        dispatch({ type: UPDATE_REJECTED });
        throw error;
    }
};

export const fetchAutomatedNotificationSettings = () => async (dispatch) => {
    dispatch({ type: GET_AUTOMATED_SETTING_START });
    try {
        const response = await axiosInstance.get(
            '/business/automated-notifications'
        );
        dispatch({
            type: GET_AUTOMATED_SETTING_SUCCESS,
            payload: response.data,
        });
        return response.data;
    } catch (error) {
        dispatch({ type: GET_AUTOMATED_SETTING_ERROR });
        throw error;
    }
};
export const updateAutomatedNotificationSettings =
    (data, message, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: UPDATE_AUTOMATED_SETTING_START });
        try {
            const response = await axiosInstance.put(
                '/business/automated-notifications',
                data
            );
            await dispatch(fetchAutomatedNotificationSettings());
            dispatch({ type: UPDATE_AUTOMATED_SETTING_SUCCESS });
            toast.success(message);
            onSuccess();
            return response.data;
        } catch (error) {
            dispatch({ type: UPDATE_AUTOMATED_SETTING_ERROR });
            throw error;
        }
    };

export const buyEmailCradit =
    (data, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
        try {
            const response = await axiosInstance.post(
                '/business/email-credit/buy',
                data
            );
            await dispatch(getDetails());
            toast.success(response.data?.message);
            onSuccess();
            return response.data;
        } catch (error) {
            toast.error(error?.data?.message);
            onError();
            throw error;
        }
    };

export const delImage = (id) => async (dispatch) => {
    dispatch({ type: UPDATE_PENDING });
    try {
        const response = await axiosInstance.delete(`/business/image/${id}`);
        dispatch({ type: UPDATE_REJECTED });
        console.log('deleteImageResponse', response.data);
        return response.data;
    } catch (error) {
        console.log('Error deleting image');
        dispatch({ type: UPDATE_REJECTED });
        throw error;
    }
};
export const getPricingInfo = (id) => async (dispatch) => {
    dispatch({ type: GET_PRICING_START });
    try {
        const response = await axiosInstance.get(`/stripe/price`);
        dispatch({ type: GET_PRICING_SUCCESS, payload: response.data.data });
        return response.data;
    } catch (error) {
        dispatch({ type: GET_PRICING_ERROR });
        throw error;
    }
};

export const handleInsta = (data) => ({
    type: INSTA_RES,
    payload: data,
});
export const resetInstaFiles = () => ({
    type: RESET_INSTA_FILES,
});

export const getCountrySpecs = (country) => async (dispatch) => {
    dispatch({ type: GET_COUNTRY_SPEC_PENDING });
    try {
        const response = await axiosInstance.get(
            `stripe/country-spec/${country}`
        );
        dispatch({
            type: GET_COUNTRY_SPEC_SUCCESS,
            payload:
                response.data.data?.verification_fields?.individual?.minimum,
        });
        return response.data;
    } catch (error) {
        console.log('Error Updating Business Details');
        dispatch({ type: GET_COUNTRY_SPEC_REJECTED });
        throw error;
    }
};

export const connectAccount = (data, onSuccess) => async (dispatch) => {
    dispatch({ type: CONNECT_ACCOUNT_PENDING });
    try {
        const response = await axiosInstance.post(
            'stripe/connect-account',
            data
        );
        dispatch({
            type: CONNECT_ACCOUNT_SUCCESS,
            payload: response.data.data,
        });
        if (response.data.success === true) {
            onSuccess(response.data.response);
            toast.success(response.data?.message);
        } else {
            toast.error(response.data?.message);
        }
        return response.data.response;
    } catch (error) {
        toast.error(error?.data?.message);
        console.log('Error connecting account');
        dispatch({ type: CONNECT_ACCOUNT_REJECTED });
        throw error;
    }
};

export const connectAccountStatus = (id) => async (dispatch) => {
    dispatch({ type: CONNECT_ACCOUNT_STATUS_PENDING });
    try {
        const response = await axiosInstance.get(
            `stripe/connect-account-status/${id}`
        );
        dispatch({
            type: CONNECT_ACCOUNT_STATUS_SUCCESS,
            payload: response.data.data,
        });
        toast.success(response.data?.message);
        return response.data.data;
    } catch (error) {
        console.log('Error connecting account');
        dispatch({ type: CONNECT_ACCOUNT_STATUS_REJECTED });
        throw error;
    }
};

export const getConnectAccount = (id) => async (dispatch) => {
    dispatch({ type: GET_CONNECT_ACCOUNT_PENDING });
    try {
        const response = await axiosInstance.get(
            `stripe/connect-account/${id}`
        );
        dispatch({
            type: GET_CONNECT_ACCOUNT_SUCCESS,
            payload: response.data.data,
        });
        toast.success(response.data?.message);
        return response.data.data;
    } catch (error) {
        console.log('Error connecting account');
        dispatch({ type: GET_CONNECT_ACCOUNT_REJECTED });
        throw error;
    }
};

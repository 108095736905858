import React, { useEffect, useState } from 'react';
import axios from '../../config/Axios';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../redux/users/actionCreator';
import { toast } from 'react-toastify';
import Navbar from '../Navbar';
import withAdminLayout from '../../layout/withAdminLayout';
import {
    connectAccountStatus,
    getConnectAccount,
    getDetails,
    getPricingInfo,
} from '../../redux/businessAccount/detailsAction';
import Loading from '../../components/Loading';
import ConnectAccountModal from './updateStripe';

const Stripe = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const user = useSelector((state) => state.user.userData);
    const { pricing, isPriceLoading, accountStatus, connectAccount } =
        useSelector((state) => state.businessDetails);
    const [dispatched, setDispatched] = useState(false);
    const [connectId, setConnectId] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const checkSubscription = (planId) => {
        return (
            !user?.subscription?.is_deleted &&
            user?.subscription?.plan_id === planId
        );
    };

    const updateUser = (user) => {
        dispatch(setUser(user));
    };

    const handleMonthlySubscription = async () => {
        if (
            checkSubscription(
                pricing?.find((item) => item?.name === 'Monthly')?.priceId
            )
        ) {
        } else await handleClick({ priceId: 'month', email: user?.email });
    };

    const handleAnnualSubscription = async () => {
        if (
            checkSubscription(
                pricing?.find((item) => item?.name === 'Yearly')?.priceId
            )
        ) {
        } else await handleClick({ priceId: 'year', email: user?.email });
    };

    const getSubscription = async (sessionId) => {
        const response = await axios.post('stripe/subscription', { sessionId });
        if (response.data) {
            await updateUserSubscription(response.data);
        }
    };

    const updateUserSubscription = async (data) => {
        try {
            const response = await axios.post(
                'user/update-user-subscription',
                data
            );
            console.log('In Success: Update user subscription', data);
            dispatch(setUser(response.data));
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                    'Unable to subscribe this plan'
            );
            console.log('Error: update-user-subscription', error);
        }
    };
    const handleClick = async (data) => {
        try {
            const response = await axios.post(
                'stripe/create-checkout-session',
                data
            );
            console.log(response.data);
            window.location = response.data.url;
        } catch (error) {
            toast(
                error?.response?.data?.message ||
                    'Unable create checkout session'
            );
        }
    };

    const handleConnect = async () => {
        try {
            if (!user?.stripe_connect_id) {
                const response = await axios.post('stripe/accounts', { user });
                console.log(response.data);
                localStorage.setItem(
                    'userData',
                    JSON.stringify(response.data.user)
                );
                window.location = response.data.accountLink.url;
            }
        } catch (error) {
            toast(error?.response?.data?.message || 'Unable to connect');
            console.error(
                'Failed to create account:',
                error?.response?.data?.message
            );
        }
    };
    const handleCancelSubscription = async () => {
        try {
            if (user?.subscription !== null) {
                await axios.post('stripe/unsubscribe', {
                    user,
                });
                dispatch(getDetails());
            }
        } catch (error) {
            toast(
                error?.response?.data?.message ||
                    'Unable to cancel subscription'
            );
            console.error(
                'Failed to cancel subscription:',
                error.response.data.message
            );
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const session_id = searchParams.get('session_id');

        if (session_id) {
            getSubscription(session_id);
            history.push('pricing-plan');
        }
    }, [location.search]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const success = searchParams.get('success');

        if (success && !dispatched) {
            const userDataString = localStorage.getItem('userData');
            const userData = JSON.parse(userDataString);

            setConnectId(userData?.stripe_connect_id);

            setDispatched(true);
        }
    }, [dispatch, dispatched]);

    useEffect(() => {
        const userDataString = localStorage.getItem('userData');
        const userData = JSON.parse(userDataString);

        if (connectId) {
            updateUser(userData);
            history.push('pricing-plan');
        }
    }, [connectId]);

    useEffect(() => {
        dispatch(getPricingInfo());
        dispatch(connectAccountStatus(user?.stripe_connect_id));
        dispatch(getConnectAccount(user?.stripe_connect_id));
    }, []);

    return (
        <div className={`container-fluid`}>
            {isPriceLoading ? (
                <Loading />
            ) : (
                <div className={`row`}>
                    <div
                        className={`col-12 px-0 bg-ff br-top-left-22 min-h-100vh`}
                    >
                        <div className={`container-fluid`}>
                            <Navbar />
                        </div>
                        <div className={`container-fluid`}>
                            <div
                                className={`row align-items-center justify-content-center`}
                            >
                                <div
                                    className={`col-auto text-center pt-lg-0 pt-5`}
                                >
                                    <h6
                                        className={`m-0 pb-16 f-36 fw-normal poly c-0f151d`}
                                    >
                                        View & Access All Appointments
                                    </h6>
                                </div>
                            </div>
                            <div
                                className={`row align-items-center justify-content-center`}
                            >
                                <div
                                    className={`col-md-4 col-12 text-center px-43`}
                                >
                                    <span
                                        className={`d-block f-14 fw-normal text-gray poppins`}
                                    >
                                        A payment plan has been carefully
                                        curated for you in order to be able to
                                        make and access all appointments from
                                        your clients
                                    </span>
                                </div>
                            </div>
                            <div
                                className={`row align-items-center justify-content-center mt-3 `}
                            >
                                <div className="col-6 text-center px-43">
                                    <button
                                        className="btn btn-get-it-now-outline mb-2"
                                        onClick={handleConnect}
                                    >
                                        {accountStatus?.status !== 'verified' ||
                                        accountStatus?.requirements?.length > 0
                                            ? 'Pending'
                                            : user?.stripe_connect_id ||
                                              connectId
                                            ? 'Connected'
                                            : 'Connect to Stripe'}
                                    </button>
                                    {(accountStatus?.status !== 'verified' ||
                                        accountStatus?.requirements?.length >
                                            0 ||
                                        !user?.stripe_connect_id) && (
                                        <button
                                            className="btn btn-get-it-now-outline"
                                            onClick={() => setIsModalOpen(true)}
                                        >
                                            Update Stripe Account
                                        </button>
                                    )}
                                </div>
                            </div>
                            {user?.subscription !== null &&
                                !user?.subscription?.is_deleted && (
                                    <div
                                        className={`row align-items-center justify-content-center mt-3 `}
                                    >
                                        <div
                                            className={`col-12 text-center px-43`}
                                        >
                                            <button
                                                className={`btn btn-get-it-now-outline`}
                                                onClick={
                                                    handleCancelSubscription
                                                }
                                            >
                                                Cancel Subscription
                                            </button>
                                        </div>
                                    </div>
                                )}

                            <div
                                className={`row align-items-center justify-content-center py-80`}
                            >
                                <div
                                    className={`col-md-auto col-12 ps-md-0 pe-2 pb-3`}
                                >
                                    <div
                                        className={`card price-card bg-ff br-top-left-22 mx-auto`}
                                    >
                                        <div className={`container-fluid`}>
                                            <div
                                                className={`row align-items-center pb-23`}
                                            >
                                                <div
                                                    className={`col-auto ps-0`}
                                                >
                                                    <h6
                                                        className={`f-28 poly fw-normal c-334155 mb-0`}
                                                    >
                                                        Yearly
                                                    </h6>
                                                </div>
                                                <div
                                                    className={`col-auto px-0`}
                                                >
                                                    <h6
                                                        className={`recommended m-0`}
                                                    >
                                                        Recommended
                                                    </h6>
                                                </div>
                                            </div>

                                            <div
                                                className={`row align-items-center pb-16 border-b-1-ddd`}
                                            >
                                                <div
                                                    className={`col-auto ps-0`}
                                                >
                                                    <h6
                                                        className={`f-14 poppins fw-normal c-667085 mb-0`}
                                                    >
                                                        A payment plan has been
                                                        carefully curated for
                                                        you in order to be able
                                                        to make
                                                    </h6>
                                                </div>
                                            </div>

                                            <div
                                                className={`row align-items-center pt-24 pb-16 border-b-1-ddd`}
                                            >
                                                <div
                                                    className={`col-auto ps-0 pe-2`}
                                                >
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M6 12.75L9.75 16.5L18 7.5"
                                                            stroke="#FFC931"
                                                            strokeWidth="1.5"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className={`col-10 ps-0`}>
                                                    <h6
                                                        className={`f-12 poppins fw-normal c-667085 mb-0`}
                                                    >
                                                        View All Appointments
                                                    </h6>
                                                </div>
                                                <div
                                                    className={`col-auto ps-0 pe-2`}
                                                >
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M6 12.75L9.75 16.5L18 7.5"
                                                            stroke="#FFC931"
                                                            strokeWidth="1.5"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className={`col-10 ps-0`}>
                                                    <h6
                                                        className={`f-12 poppins fw-normal c-667085 mb-0`}
                                                    >
                                                        Get Notifications
                                                    </h6>
                                                </div>
                                                <div
                                                    className={`col-auto ps-0 pe-2`}
                                                >
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M6 12.75L9.75 16.5L18 7.5"
                                                            stroke="#FFC931"
                                                            strokeWidth="1.5"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className={`col-10 ps-0`}>
                                                    <h6
                                                        className={`f-12 poppins fw-normal c-667085 mb-0`}
                                                    >
                                                        Automated preferences
                                                    </h6>
                                                </div>
                                            </div>
                                            <div
                                                className={`row align-items-center py-24`}
                                            >
                                                <div
                                                    className={`col-auto ps-0`}
                                                >
                                                    <h6
                                                        className={`f-24 poppins fw-medium c-334155 d-flex align-items-center`}
                                                    >
                                                        £
                                                        {
                                                            pricing?.find(
                                                                (item) =>
                                                                    item?.name ===
                                                                    'Yearly'
                                                            )?.price
                                                        }
                                                        <span
                                                            className={`f-12 poppins c-667085`}
                                                        >
                                                            /year
                                                        </span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            className={`btn btn-get-it-now`}
                                            onClick={handleAnnualSubscription}
                                        >
                                            {checkSubscription(
                                                pricing?.find(
                                                    (item) =>
                                                        item?.name === 'Yearly'
                                                )?.priceId
                                            )
                                                ? 'Subscribed'
                                                : `Annual £ ${
                                                      pricing?.find(
                                                          (item) =>
                                                              item?.name ===
                                                              'Yearly'
                                                      )?.price
                                                  }`}
                                        </button>
                                    </div>
                                </div>

                                <div
                                    className={`col-md-auto col-12 ps-2 pe-md-0 pb-3`}
                                >
                                    <div
                                        className={`card price-card bg-ff br-top-left-22 mx-auto`}
                                    >
                                        <div className={`container-fluid`}>
                                            <div
                                                className={`row align-items-center pb-23`}
                                            >
                                                <div
                                                    className={`col-auto ps-0`}
                                                >
                                                    <h6
                                                        className={`f-28 poly fw-normal c-334155 mb-0`}
                                                    >
                                                        Monthly
                                                    </h6>
                                                </div>
                                            </div>
                                            <div
                                                className={`row align-items-center pb-16 border-b-1-ddd`}
                                            >
                                                <div
                                                    className={`col-auto ps-0`}
                                                >
                                                    <h6
                                                        className={`f-14 poppins fw-normal c-667085 mb-0`}
                                                    >
                                                        A payment plan has been
                                                        carefully curated for
                                                        you in order to be able
                                                        to make
                                                    </h6>
                                                </div>
                                            </div>
                                            <div
                                                className={`row align-items-center pt-24 pb-16 border-b-1-ddd`}
                                            >
                                                <div
                                                    className={`col-auto ps-0 pe-2`}
                                                >
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M6 12.75L9.75 16.5L18 7.5"
                                                            stroke="#FFC931"
                                                            strokeWidth="1.5"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className={`col-10 ps-0`}>
                                                    <h6
                                                        className={`f-12 poppins fw-normal c-667085 mb-0`}
                                                    >
                                                        View All Appointments
                                                    </h6>
                                                </div>
                                                <div
                                                    className={`col-auto ps-0 pe-2`}
                                                >
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M6 12.75L9.75 16.5L18 7.5"
                                                            stroke="#FFC931"
                                                            strokeWidth="1.5"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className={`col-10 ps-0`}>
                                                    <h6
                                                        className={`f-12 poppins fw-normal c-667085 mb-0`}
                                                    >
                                                        Get Notifications
                                                    </h6>
                                                </div>
                                                <div
                                                    className={`col-auto ps-0 pe-2`}
                                                >
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M6 12.75L9.75 16.5L18 7.5"
                                                            stroke="#FFC931"
                                                            strokeWidth="1.5"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className={`col-10 ps-0`}>
                                                    <h6
                                                        className={`f-12 poppins fw-normal c-667085 mb-0`}
                                                    >
                                                        Automated preferences
                                                    </h6>
                                                </div>
                                            </div>
                                            <div
                                                className={`row align-items-center py-24`}
                                            >
                                                <div
                                                    className={`col-auto ps-0`}
                                                >
                                                    <h6
                                                        className={`f-24 poppins fw-medium c-334155 d-flex align-items-center`}
                                                    >
                                                        £{' '}
                                                        {
                                                            pricing?.find(
                                                                (item) =>
                                                                    item?.name ===
                                                                    'Monthly'
                                                            )?.price
                                                        }
                                                        <span
                                                            className={`f-12 poppins c-667085`}
                                                        >
                                                            /month
                                                        </span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            className={`btn btn-get-it-now-outline`}
                                            onClick={handleMonthlySubscription}
                                        >
                                            {checkSubscription(
                                                pricing?.find(
                                                    (item) =>
                                                        item?.name === 'Monthly'
                                                )?.priceId
                                            )
                                                ? 'Subscribed'
                                                : `Monthly £${
                                                      pricing?.find(
                                                          (item) =>
                                                              item?.name ===
                                                              'Monthly'
                                                      )?.price
                                                  }`}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isModalOpen && (
                <ConnectAccountModal
                    formData={accountStatus}
                    isModalOpen={isModalOpen}
                    handleClose={() => setIsModalOpen(false)}
                />
            )}
        </div>
    );
};

export default withAdminLayout(Stripe);

import React, { useEffect, useState } from 'react';
import Stepper from './stepper';
import Form from './form';
import './step.css';

const BusinessForm = () => {
    const [formData, setFormData] = useState({
        step: 0,
        name: '',
        website: '',
        services: [],
        location: '',
        images: [],
    });

    const steps = [
        'Business Name',
        'Services you offer',
        'Set Location',
        'Upload Photos',
        'Connect Account',
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleNext = () => {
        setFormData((prevData) => ({
            ...prevData,
            step: prevData.step + 1,
        }));
    };

    const handlePrev = () => {
        setFormData((prevData) => ({
            ...prevData,
            step: prevData.step - 1,
        }));
    };

    useEffect(() => {
        if (window.location.pathname.includes('upload-from-instagram')) {
            const storedFormData = JSON.parse(localStorage.getItem('formData'));
            if (storedFormData) {
                setFormData(storedFormData);
            }
        }
    }, []);

    return (
        <div>
            <Stepper steps={steps} currentStep={formData.step} />
            <Form
                setFormData={setFormData}
                formData={formData}
                handleChange={handleChange}
                handleNext={handleNext}
                handlePrev={handlePrev}
            />
        </div>
    );
};

export default BusinessForm;

import React, { Fragment } from 'react';

const Stepper = ({ steps, currentStep }) => {
    return (
        <div className={`container-fluid`}>
            <div className={`row`}>
                <div className={`col-12 px-0 border-b-1-e8`}>
                    <div className={`container`}>
                        <div
                            className={`row align-items-center justify-content-center flex-wrap-nowrap pt-40 pb-32`}
                        >
                            {steps.map((step, index) => (
                                <Fragment key={index}>
                                    <div
                                        className={`col-auto step-after position-relative ${
                                            index === currentStep
                                                ? 'active'
                                                : ''
                                        }`}
                                    >
                                        {/* d-4-none*/}
                                        <div className={`container-fluid`}>
                                            <div
                                                className={`row align-items-center`}
                                            >
                                                {index < currentStep ? (
                                                    // Tick

                                                    <div
                                                        className={`col-auto ps-0 pr-13`}
                                                    >
                                                        <div
                                                            className={`step-check`}
                                                        >
                                                            <svg
                                                                width="12"
                                                                height="12"
                                                                viewBox="0 0 12 12"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M10.7653 3.64052L4.76528 9.64052C4.73045 9.67539 4.68909 9.70305 4.64357 9.72192C4.59804 9.74079 4.54925 9.7505 4.49996 9.7505C4.45068 9.7505 4.40189 9.74079 4.35636 9.72192C4.31084 9.70305 4.26948 9.67539 4.23465 9.64052L1.60965 7.01552C1.53929 6.94516 1.49976 6.84972 1.49976 6.75021C1.49976 6.6507 1.53929 6.55526 1.60965 6.4849C1.68002 6.41453 1.77545 6.375 1.87496 6.375C1.97448 6.375 2.06991 6.41453 2.14028 6.4849L4.49996 8.84505L10.2347 3.1099C10.305 3.03953 10.4005 3 10.5 3C10.5995 3 10.6949 3.03953 10.7653 3.1099C10.8356 3.18026 10.8752 3.2757 10.8752 3.37521C10.8752 3.47472 10.8356 3.57016 10.7653 3.64052Z"
                                                                    fill="white"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    // Number
                                                    <div
                                                        className={`col-auto ps-0 pr-13`}
                                                    >
                                                        <div
                                                            className={`num-border`}
                                                        >
                                                            {index + 1}
                                                        </div>
                                                    </div>
                                                )}

                                                <div
                                                    className={`col-auto px-0 d-md-block d-none`}
                                                >
                                                    <h6
                                                        className={`m-0 c-0f151d poppins f-16 fw-normal lh-24`}
                                                    >
                                                        {step}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`col hl-col px-0 hide_Step_xs`}
                                    >
                                        <div className={`hl `} />
                                    </div>
                                </Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Stepper;

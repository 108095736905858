import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    getAllSubCategories,
    onSelectedServicesChange,
} from '../../redux/services/servicesActions';
import { useDispatch } from 'react-redux';
import { isAuthenticated } from '../../helpers/functions';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const ServiceBookNow = ({ item, id, hide }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { userData } = useSelector((state) => state.user);
    const { subCategories } = useSelector((state) => state.services);
    console.log(item);
    useEffect(() => {
        dispatch(getAllSubCategories(id));
    }, [dispatch]);

    return (
        <div className="w-100 mt-3 service-book-now-card">
            <div className="row">
                <div className="col-md-8 cols-12">
                    <div className="d-flex align-items-center">
                        <p className="m-0 c-0f151d f-18 fw-medium poppins">
                            {item?.name}
                        </p>
                        <div className="mx-1 service-dot"></div>{' '}
                        <p className="m-0 c-667085 f-16 poppins">
                            {item?.duration}
                        </p>
                    </div>
                    <p className="m-0 c-101828 f-12 poppins">
                        {(Array.isArray(subCategories) &&
                            subCategories.find(
                                (sub) => sub.id === item?.businessSubcategoryId
                            )?.subCategory) ||
                            ''}
                    </p>
                    <p className="m-0 c-101828 f-16 fw-medium poppins">
                        £{item?.price}
                    </p>
                    <small className="mb-0 mt-1 text-dark">
                        {item?.description}
                    </small>

                    {item?.addOns?.length > 0 && (
                        <h2 className="fs-6 mt-3">+ Available Add-Ons</h2>
                    )}
                    <div className="d-flex flex-wrap align-items-center gap-2">
                        {item?.addOns?.map((adon) => (
                            <div className="services-header-item d-flex align-items-center poppins f-14">
                                {adon?.name} (£{adon?.price})
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-md-4 cols-12 d-flex align-items-start justify-content-md-end justify-content-start mt-2 mt-md-0">
                    <button
                        type="button"
                        className="text-decoration-none book-now-transparent c-667085 fs-16 poppins"
                        onClick={() => {
                            if (
                                isAuthenticated() &&
                                userData?.role === 'business'
                            ) {
                                toast.error(
                                    'To book an appointment, you need to create a customer account first.'
                                );
                            } else if (
                                isAuthenticated() &&
                                userData?.role === 'customer' &&
                                userData?.status === 'inactive'
                            ) {
                                toast.error(
                                    'Your account is temporarily deactivated. Please activate your account from the profile settings before booking an appointment.'
                                );
                            } else {
                                dispatch(onSelectedServicesChange([item]));
                                if (
                                    isAuthenticated() &&
                                    userData?.role === 'customer'
                                ) {
                                    history.push(
                                        `/${
                                            hide
                                                ? 'stylist'
                                                : 'business-details'
                                        }/${id}/services/book-appointment`
                                    );
                                } else {
                                    history.push(`/login-customer`, {
                                        fallback: `/${
                                            hide
                                                ? 'stylist'
                                                : 'business-details'
                                        }/${id}/services/book-appointment`,
                                    });
                                }
                            }
                        }}
                    >
                        Book Now
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ServiceBookNow;

import React from 'react';
import Footer from '../layout/Footer';
import Header from '../appointments/Header';
import SearchBar from '../features/services/search-bar';

const NewPagesLayout = (props) => {
    return (
        <div className="customer_layout">
            <Header hide={props?.hideSearch} />

            {!props?.hideSearch && <SearchBar />}
            <div className="customer_layout_children">{props.children}</div>
            <Footer />
        </div>
    );
};

export default NewPagesLayout;

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';

export const REORDER_MAIN_SERVICES_START = 'REORDER_MAIN_SERVICES_START';
export const REORDER_MAIN_SERVICES_SUCCESS = 'REORDER_MAIN_SERVICES_SUCCESS';
export const REORDER_MAIN_SERVICES_ERROR = 'REORDER_MAIN_SERVICES_ERROR';

export const DELETE_SERVICE_START = 'DELETE_SERVICE_START';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_REJECTED = 'DELETE_SERVICE_REJECTED';

export const SEARCH_SERVICES_START = 'SEARCH_SERVICES_START';
export const SEARCH_SERVICES_SUCCESS = 'SEARCH_SERVICES_SUCCESS';
export const SEARCH_SERVICES_REJECTED = 'SEARCH_SERVICES_REJECTED';

export const LOAD_MORE_START = 'LOAD_MORE_START';
export const LOAD_MORE_SUCCESS = 'LOAD_MORE_SUCCESS';
export const LOAD_MORE_ERROR = 'LOAD_MORE_ERROR';

export const FILTER_VALUE_CHANGE = 'FILTER_VALUE_CHANGE';
export const EXTRA_FIELD_CHANGE = 'EXTRA_FIELD_CHANGE';
export const RESET_FILTER_VALUES = 'RESET_FILTER_VALUES';
export const SELECTED_SERVICES_CHANGE = 'SELECTED_SERVICES_CHANGE';
export const SELECTED_SERVICES_RESET = 'SELECTED_SERVICES_RESET';
export const LOAD_MORE = 'LOAD_MORE';
export const RESET_LOAD_MORE = 'RESET_LOAD_MORE';

export const FETCH_SINGLE_BUSINESS_START = 'FETCH_SINGLE_BUSINESS_START';
export const FETCH_SINGLE_BUSINESS_SUCCESS = 'FETCH_SINGLE_BUSINESS_SUCCESS';
export const FETCH_SINGLE_BUSINESS_REJECTED = 'FETCH_SINGLE_BUSINESS_REJECTED';

export const FETCH_LATEST_BUSINESS_START = 'FETCH_LATEST_BUSINESS_START';
export const FETCH_LATEST_BUSINESS_SUCCESS = 'FETCH_LATEST_BUSINESS_SUCCESS';
export const FETCH_LATEST_BUSINESS_ERROR = 'FETCH_LATEST_BUSINESS_ERROR';

export const FETCH_RECOMEND_BUSINESS_START = 'FETCH_RECOMEND_BUSINESS_START';
export const FETCH_RECOMEND_BUSINESS_SUCCESS =
    'FETCH_RECOMEND_BUSINESS_SUCCESS';
export const FETCH_RECOMEND_BUSINESS_ERROR = 'FETCH_RECOMEND_BUSINESS_ERROR';

export const GET_ALL_FAV_START = 'GET_ALL_FAV_START';
export const GET_ALL_FAV_SUCCESS = 'GET_ALL_FAV_SUCCESS';
export const GET_ALL_FAV_ERROR = 'GET_ALL_FAV_ERROR';

export const ADD_FAV_START = 'ADD_FAV_START';
export const ADD_FAV_SUCCESS = 'ADD_FAV_SUCCESS';
export const ADD_FAV_ERROR = 'ADD_FAV_ERROR';

export const GET_ALL_SUB_CATEGORY_PENDING = 'GET_ALL_SUB_CATEGORY_PENDING';
export const GET_ALL_SUB_CATEGORY_SUCCESS = 'GET_ALL_SUB_CATEGORY_SUCCESS';
export const GET_ALL_SUB_CATEGORY_ERROR = 'GET_ALL_SUB_CATEGORY_ERROR';
export const UPDATE_SUB_CATEGORY = 'UPDATE_SUB_CATEGORY';
export const DELETE_SUB_CATEGORY = 'DELETE_SUB_CATEGORY';

export const FETCH_ALL_BUSINESS_NAMES_START = 'FETCH_ALL_BUSINESS_NAMES_START';
export const FETCH_ALL_BUSINESS_NAMES_SUCCESS =
    'FETCH_ALL_BUSINESS_NAMES_SUCCESS';
export const FETCH_ALL_BUSINESS_NAMES_ERROR = 'FETCH_ALL_BUSINESS_NAMES_ERROR';

import React, { Fragment, useState } from 'react';
import Business from './business';
import Services from './services';
import Location from './location';
import Pictures from './pictures';
import ConnectAccount from './connectAccount';

const Form = ({
    formData,
    handleChange,
    handleNext,
    handlePrev,
    setFormData,
}) => {
    const [isOnline, setIsOnline] = useState(false);
    const [previewImages, setPreviewImages] = useState([]);

    return (
        <Fragment>
            {formData.step === 0 && (
                <Business
                    handleNext={handleNext}
                    handleChange={handleChange}
                    formData={formData}
                />
            )}
            {formData.step === 1 && (
                <Services
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    setFormData={setFormData}
                    formData={formData}
                />
            )}
            {formData.step === 2 && (
                <Location
                    isOnline={isOnline}
                    setIsOnline={setIsOnline}
                    handlePrev={handlePrev}
                    handleChange={handleChange}
                    setFormData={setFormData}
                    formData={formData}
                    handleNext={handleNext}
                />
            )}
            {formData.step === 3 && (
                <Pictures
                    handlePrev={handlePrev}
                    handleNext={handleNext}
                    handleChange={handleChange}
                    setFormData={setFormData}
                    formData={formData}
                    previewImages={previewImages}
                    setPreviewImages={setPreviewImages}
                />
            )}
            {formData.step === 4 && (
                <ConnectAccount
                    handlePrev={handlePrev}
                    handleChange={handleChange}
                    setFormData={setFormData}
                    formData={formData}
                />
            )}
        </Fragment>
    );
};

export default Form;

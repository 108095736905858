import moment from 'moment';
import { SERVICES } from '../../helpers/constants';
import {
    ADD_FAV_ERROR,
    ADD_FAV_START,
    ADD_FAV_SUCCESS,
    DELETE_SERVICE_REJECTED,
    DELETE_SERVICE_START,
    DELETE_SERVICE_SUCCESS,
    DELETE_SUB_CATEGORY,
    EXTRA_FIELD_CHANGE,
    FETCH_ALL_BUSINESS_NAMES_ERROR,
    FETCH_ALL_BUSINESS_NAMES_START,
    FETCH_ALL_BUSINESS_NAMES_SUCCESS,
    FETCH_LATEST_BUSINESS_ERROR,
    FETCH_LATEST_BUSINESS_START,
    FETCH_LATEST_BUSINESS_SUCCESS,
    FETCH_RECOMEND_BUSINESS_ERROR,
    FETCH_RECOMEND_BUSINESS_SUCCESS,
    FETCH_SERVICES_SUCCESS,
    FETCH_SINGLE_BUSINESS_REJECTED,
    FETCH_SINGLE_BUSINESS_START,
    FETCH_SINGLE_BUSINESS_SUCCESS,
    FILTER_VALUE_CHANGE,
    FULFILLED,
    GET_ALL_FAV_ERROR,
    GET_ALL_FAV_START,
    GET_ALL_FAV_SUCCESS,
    GET_ALL_SUB_CATEGORY_ERROR,
    GET_ALL_SUB_CATEGORY_PENDING,
    GET_ALL_SUB_CATEGORY_SUCCESS,
    LOAD_MORE,
    LOAD_MORE_ERROR,
    LOAD_MORE_START,
    LOAD_MORE_SUCCESS,
    PENDING,
    REJECTED,
    RESET_FILTER_VALUES,
    RESET_LOAD_MORE,
    SEARCH_SERVICES_REJECTED,
    SEARCH_SERVICES_START,
    SEARCH_SERVICES_SUCCESS,
    SELECTED_SERVICES_CHANGE,
    SELECTED_SERVICES_RESET,
    UPDATE_SUB_CATEGORY,
} from './servicesTypes';

const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
// const services = SERVICES.map((item) => item.name);

const today = new Date();
const formattedDate = moment(today).format('DD/MM/YYYY');

const filterInitialValue = {
    latitude: '',
    longitude: '',
    service: [],
    toTime: '24:00',
    fromTime: '01:00',
    date: formattedDate,
    from: null,
    to: null,
};

const initialExtraFields = {
    localDate: new Date().toISOString(),
    localAddress: '',
    timeOption: 'anyTime',
    dateOption: 'All Days',
};

const initialState = {
    categorizedServices: null,
    searchedData: [],
    isLoading: false,
    page: 1,
    isLoadingMore: false,
    isBusinessGetting: false,
    isDeleting: false,
    error: null,
    filteredValues: filterInitialValue,
    extraFields: initialExtraFields,
    singleBusinessDetails: null,
    selectedServices: [],
    searchPagination: null,
    latestBusinesses: [],
    recomendedBusinesses: [],
    isFavLoading: false,
    favourites: [],
    subCategories: [],
    isSubCatDeleting: false,
    isSubCatUpdating: false,
    businessNames: null,
};

const servicesReducers = (state = initialState, action) => {
    switch (action.type) {
        case PENDING:
            // case FETCH_LATEST_BUSINESS_START:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case FULFILLED:
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        case FETCH_SERVICES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                categorizedServices: action.payload.data,
                error: null,
            };

        case FILTER_VALUE_CHANGE:
            return {
                ...state,
                filteredValues: action.payload,
            };

        case EXTRA_FIELD_CHANGE:
            return {
                ...state,
                extraFields: action.payload,
            };

        case SELECTED_SERVICES_CHANGE:
            return {
                ...state,
                selectedServices: action.payload,
            };
        case SELECTED_SERVICES_RESET:
            return {
                ...state,
                selectedServices: [],
            };
        case LOAD_MORE:
            return {
                ...state,
                page: state.page + 1,
            };
        case RESET_LOAD_MORE:
            return {
                ...state,
                page: 1,
            };

        case RESET_FILTER_VALUES:
            return {
                ...state,
                filteredValues: filterInitialValue,
                extraFields: initialExtraFields,
            };

        case DELETE_SERVICE_START:
            return {
                ...state,
                isDeleting: true,
                error: null,
            };

        case DELETE_SERVICE_SUCCESS:
            return {
                ...state,
                isDeleting: false,
                error: null,
            };

        case SEARCH_SERVICES_START:
            return {
                ...state,
                isLoading: true,
                searchedData: [],
                searchPagination: null,
                error: null,
            };

        case SEARCH_SERVICES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                searchedData: action.payload.data,
                searchPagination: action.payload?.pagination,
                error: null,
            };
        case LOAD_MORE_START:
            return {
                ...state,
                isLoadingMore: true,
            };

        case LOAD_MORE_SUCCESS:
            const existingIds = new Set(
                state.searchedData.map((item) => item.id)
            );
            const newData = action.payload.data.filter(
                (item) => !existingIds.has(item.id)
            );
            return {
                ...state,
                isLoadingMore: false,
                searchedData: [...state.searchedData, ...newData],
                error: null,
            };
        case FETCH_SINGLE_BUSINESS_START:
            return {
                ...state,
                isBusinessGetting: true,
                singleBusinessDetails: null,
            };
        case FETCH_SINGLE_BUSINESS_SUCCESS:
            return {
                ...state,
                singleBusinessDetails: action.payload,
                isBusinessGetting: false,
            };
        case FETCH_LATEST_BUSINESS_SUCCESS:
            return {
                ...state,
                latestBusinesses: action.payload,
                isLoading: false,
            };
        case FETCH_RECOMEND_BUSINESS_SUCCESS:
            return {
                ...state,
                recomendedBusinesses: action.payload,
                isLoading: false,
            };
        case FETCH_SINGLE_BUSINESS_REJECTED:
            return {
                ...state,
                isBusinessGetting: false,
            };
        case GET_ALL_FAV_START:
            return {
                ...state,
                isFavLoading: true,
                favourites: [],
            };
        case GET_ALL_FAV_SUCCESS:
            return {
                ...state,
                isFavLoading: false,
                favourites: action.payload,
            };
        case GET_ALL_FAV_ERROR:
            return {
                ...state,
                isFavLoading: false,
                favourites: [],
            };

        case ADD_FAV_START:
            return {
                ...state,
                isFavLoading: true,
            };
        case ADD_FAV_SUCCESS:
            return {
                ...state,
                isFavLoading: false,
            };
        case ADD_FAV_ERROR:
            return {
                ...state,
                isFavLoading: false,
            };

        case GET_ALL_SUB_CATEGORY_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case GET_ALL_SUB_CATEGORY_SUCCESS:
            return {
                ...state,
                subCategories: action.payload,
                isLoading: false,
            };
        case GET_ALL_SUB_CATEGORY_ERROR:
            return {
                ...state,
                cubCategories: [],
                isLoading: false,
            };
        case UPDATE_SUB_CATEGORY:
            return {
                ...state,
                isSubCatUpdating: action.payload,
            };
        case DELETE_SUB_CATEGORY:
            return {
                ...state,
                isSubCatDeleting: action.payload,
            };

        case DELETE_SERVICE_REJECTED:
        case SEARCH_SERVICES_REJECTED:
        case FETCH_LATEST_BUSINESS_ERROR:
        case FETCH_RECOMEND_BUSINESS_ERROR:
        case LOAD_MORE_ERROR:
        case REJECTED:
            return {
                ...state,
                isDeleting: false,
                isLoading: false,
                isLoadingMore: false,
                error: action.payload,
            };
        case FETCH_ALL_BUSINESS_NAMES_START:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_ALL_BUSINESS_NAMES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                businessNames: action.payload,
            };
        case FETCH_ALL_BUSINESS_NAMES_ERROR:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default servicesReducers;

import React, { lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import {
    getAllSubCategories,
    getMyServices,
} from '../../redux/services/servicesActions';
import { useSelector } from 'react-redux';

const SubCategories = lazy(() => import('../../container/sub-categories'));

function ServiceRoutes() {
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.user);
    const { path } = useRouteMatch();
    useEffect(() => {
        dispatch(getAllSubCategories(userData?.business?.id));
        dispatch(getMyServices(2500));
    }, [dispatch]);
    return (
        <Switch>
            <Route path={path} component={SubCategories} />
        </Switch>
    );
}

export default ServiceRoutes;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    connectAccount,
    getCountrySpecs,
} from '../../redux/businessAccount/detailsAction';
import { toast } from 'react-toastify';
import { submitBusinessDetails } from '../../redux/authentication/actionCreator';

const getFieldType = (field) => {
    field = field.split('.').at(-1);
    switch (field) {
        case 'city':
        case 'line1':
        case 'postal_code':
        case 'state':
        case 'first_name':
        case 'last_name':
        case 'phone':
            return 'text';
        case 'email':
            return 'email';
        case 'date':
            return 'date';
        default:
            return 'text';
    }
};

const formatFieldName = (field) => {
    const cleanField = field.replace(
        /^(individual\.|business_profile\.|dob\.|address\.)+/,
        ''
    );
    return cleanField.replace(/\./g, ' ').replace(/_/g, ' ');
};

const formatSortCode = (value) => {
    const digitsOnly = value.replace(/\D/g, '');

    return digitsOnly
        .replace(/(\d{2})(\d{2})(\d{0,2})/, '$1-$2-$3')
        .trim()
        .replace(/-$/, '');
};

const ConnectAccount = ({ formData }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { loading } = useSelector((state) => state.auth);
    const { tempData } = useSelector((state) => state.user);
    const { countrySpecs, isLoading } = useSelector(
        (state) => state.businessDetails
    );
    console.log(countrySpecs);
    const date = new Date();
    const formattedDate = new Intl.DateTimeFormat('en-CA').format(date);
    const [dob, setDob] = useState('');
    const [routing_number, setRouting_number] = useState('');
    const [data, setData] = useState({
        business_type: 'individual',
        mcc: '5045',
        url: 'https://bestcookieco.com',
        ip: '172.18.80.19',
        type: 'custom',
        userId: tempData?.user_id,
        country: 'GB',
        currency: 'gbp',
        date: formattedDate,
    });
    useEffect(() => {
        dispatch(getCountrySpecs('GB'));
    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (name === 'dob') {
            setDob(value);
            const [year, month, day] = value.split('-');
            setData((prev) => ({
                ...prev,
                day: day,
                month: month,
                year: year,
            }));
        } else if (files) {
            setData((prev) => ({
                ...prev,
                [name]: files[0],
            }));
        } else {
            setData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleConnectSubmit = async (e) => {
        e.preventDefault();

        const formDataObj = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            const cleanKey = key.replace(
                /^(individual\.|business_profile\.|individual.dob\.|address\.|tos_acceptance\.)+/,
                ''
            );

            formDataObj.append(cleanKey, value);
        });

        formDataObj.append('routing_number', routing_number);
        dispatch(
            connectAccount(formDataObj, (response) => {
                if (response?.id) {
                    handleSubmit();
                } else {
                    console.error('Error: No response ID found');
                }
            })
        );
    };

    const handleSubmit = async () => {
        const data = new FormData();
        data.append('name', formData.name);
        data.append('website', formData.website);
        // data.append("services", formData.services);
        data.append('latitude', formData.latitude);
        data.append('longitude', formData.longitude);
        data.append('address', formData.address);
        // data.append("user_id", formData.user_id);

        data.append('user_id', tempData.user_id);
        if (formData.length === 0) {
            toast.error(
                'Please upload at least one image related to your business.'
            );
            return;
        }

        formData?.images.forEach((image, index) => {
            data.append(`images`, image);
        });
        formData?.services.forEach((item, index) => {
            data.append(`categories[${index}]`, item);
        });

        dispatch(submitBusinessDetails(data, history, tempData, () => {}));
    };
    const excludedFields = [
        'business_profile.mcc',
        'business_profile.url',
        'business_type',
        'individual.dob.day',
        'individual.dob.month',
        'individual.dob.year',
        'tos_acceptance.ip',
        'tos_acceptance.date',
        'external_account',
    ];

    return (
        <div className="container mt-5 mx-auto" style={{ maxWidth: '600px' }}>
            <h2 className="text-center mb-4">Connect Your Account</h2>
            <form
                onSubmit={handleConnectSubmit}
                className="border p-4 rounded shadow-sm"
            >
                {countrySpecs
                    ?.filter((field) => !excludedFields.includes(field))
                    .sort((a, b) => {
                        const priorityFields = [
                            'individual.first_name',
                            'individual.last_name',
                        ];
                        if (
                            priorityFields.includes(a) &&
                            !priorityFields.includes(b)
                        )
                            return -1;
                        if (
                            !priorityFields.includes(a) &&
                            priorityFields.includes(b)
                        )
                            return 1;
                        return 0;
                    })
                    .map((field) => (
                        <div className="mb-3" key={field}>
                            <label className="form-label text-capitalize">
                                {formatFieldName(field) === 'line1'
                                    ? 'Address Line 1'
                                    : formatFieldName(field)}
                            </label>
                            <input
                                type={getFieldType(field)}
                                className="form-control"
                                name={field}
                                onChange={handleChange}
                                required
                                {...(getFieldType(field) === 'date'
                                    ? {
                                          max: new Date()
                                              .toISOString()
                                              .split('T')[0],
                                      }
                                    : {})}
                            />
                        </div>
                    ))}
                <div className="mb-3">
                    <label className="form-label">
                        Account Number{' '}
                        <span className="text-muted">(e.g., 00012345)</span>
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        name="ibn_number"
                        value={data.ibn_number}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">
                        Sort Code{' '}
                        <span className="text-muted">(e.g., 10-88-00)</span>
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        name="routing_number"
                        value={routing_number}
                        onChange={(e) =>
                            setRouting_number(formatSortCode(e.target.value))
                        }
                        maxLength={8}
                        placeholder="XX-XX-XX"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Date of Birth</label>
                    <input
                        type="date"
                        className="form-control"
                        name="dob"
                        value={dob}
                        onChange={handleChange}
                        max={
                            new Date(
                                new Date().setFullYear(
                                    new Date().getFullYear() - 18
                                )
                            )
                                .toISOString()
                                .split('T')[0]
                        }
                        required
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">Identity Document</label>
                    <input
                        type="file"
                        className="form-control"
                        name="additional_document"
                        onChange={handleChange}
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="btn btn-next w-100"
                    disabled={isLoading || loading}
                >
                    {isLoading || loading ? 'Submitting...' : 'Submit'}
                </button>
            </form>
        </div>
    );
};

export default ConnectAccount;

import React from 'react';
import BreadCrum from '../breadcrum/BreadCrum';
import ImagesSection from './images-section';
import Services from './Services';
import Reviews from '../reviews/reviews';
import About from '../about/about';
import NearByYou from './NearByYou';
import BookingMap from '../bookings/BookingMap';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    addToFavourite,
    fetchAllFav,
    getSingleBusiness,
    removeFromFav,
} from '../../redux/services/servicesActions';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { heart, outlineHeart, share } from '../../assets/images/sidebarIcons';
import { saveReferralId } from '../../redux/users/actionCreator';
import { Spin } from 'antd';
import { businessAverageRating } from '../../helpers/constants';
const ServiceDetails = () => {
    const { id } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const referral = queryParams.get('referral');
    const { userData } = useSelector((state) => state.user);
    const {
        isBusinessGetting,
        singleBusinessDetails,
        favourites,
        isFavLoading,
    } = useSelector((state) => state.services);

    const handleCopyClick = async () => {
        try {
            const businessName = singleBusinessDetails?.name
                ?.toLowerCase()
                .replace(/\s+/g, '-');
            const referralId = userData?.id;

            const url = userData
                ? `${window.location.origin}/stylist/${businessName}?referral=${referralId}`
                : window.location.href;

            await navigator.clipboard.writeText(url);
            toast.success('Profile link copied to clipboard');
        } catch (error) {
            toast.error('Failed to copy URL');
            console.error('Clipboard copy failed:', error);
        }
    };

    const handleFavourite = (id, type) => {
        if (type === 'add') {
            dispatch(addToFavourite({ business: id }));
        } else {
            dispatch(removeFromFav(id));
        }
    };

    useEffect(() => {
        dispatch(getSingleBusiness(id));
        if (userData && userData.role === 'customer') {
            dispatch(fetchAllFav());
        }
        if (!userData && referral) {
            dispatch(saveReferralId(referral));
        }
    }, [id]);

    return (
        <>
            {isBusinessGetting ? (
                <Loading />
            ) : (
                <div className="container">
                    <BreadCrum
                        items={[
                            { title: 'Home', path: '/home' },
                            {
                                title: 'Business Details',
                                path: `/business-details/${singleBusinessDetails?.id}`,
                            },
                        ]}
                    />
                    <div className="row mb-3">
                        <div className={`col-md-10 cols-12`}>
                            <div className="d-flex justify-content-start align-items-start align-items-md-center flex-wrap gap-2">
                                <p className="mb-0 poly f-36 lh-36">
                                    {singleBusinessDetails?.name}
                                </p>{' '}
                                <div className="d-flex gap-1 flex-wrap">
                                    {singleBusinessDetails?.categories &&
                                        singleBusinessDetails?.categories.map(
                                            (item, index) => (
                                                <span
                                                    className="badge c-334155 poppins f-12 fw-normal lh-16 py-4rem px-12 bg-gold br-16 d-flex align-items-center"
                                                    key={index}
                                                >
                                                    {item}
                                                </span>
                                            )
                                        )}
                                </div>
                            </div>
                            <div className="d-flex justify-content-start align-items-baseline mt-3">
                                <h6
                                    className={`m-0 c-0f151d poppins f-16 fw-normal lh-16`}
                                >
                                    {businessAverageRating(
                                        singleBusinessDetails?.reviews || []
                                    )}
                                </h6>
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mx-1"
                                >
                                    <g clipPath="url(#clip0_252_296)">
                                        <path
                                            d="M2.44884 11.6623C2.40084 11.6623 2.35284 11.6383 2.30484 11.6143C2.23284 11.5663 2.18484 11.4463 2.20884 11.3503L3.26484 7.41428L0.0968438 4.84628C0.000843818 4.79828 -0.0231562 4.67828 0.000843815 4.58228C0.0248438 4.48628 0.120844 4.41428 0.216844 4.41428L4.29684 4.19828L5.76084 0.382281C5.80884 0.310281 5.90484 0.238281 6.00084 0.238281C6.09684 0.238281 6.19284 0.310281 6.21684 0.382281L7.68084 4.19828L11.7608 4.41428C11.8568 4.41428 11.9528 4.48628 11.9768 4.58228C12.0008 4.67828 11.9768 4.77428 11.9048 4.84628L8.73684 7.41428L9.79284 11.3503C9.81684 11.4463 9.79284 11.5423 9.69684 11.6143C9.62484 11.6623 9.50484 11.6863 9.43284 11.6143L6.00084 9.40628L2.56884 11.6143C2.52084 11.6623 2.49684 11.6623 2.44884 11.6623Z"
                                            fill="#334155"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_252_296">
                                            <rect
                                                width="12"
                                                height="12"
                                                fill="white"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h6
                                    className={`m-0 me-1 c-0f151d poppins f-16 fw-normal lh-16`}
                                >
                                    (
                                    {singleBusinessDetails?.reviews?.length ||
                                        0}
                                    )
                                </h6>
                                <h6
                                    className={`c-667085 f-16 fw-normal  poppins m-0`}
                                >
                                    {singleBusinessDetails?.address}
                                </h6>
                            </div>
                        </div>
                        <div
                            className={`col-md-2 cols-12 d-flex justify-content-md-end justify-content-start mt-md-0 mt-2`}
                        >
                            <div
                                className=" service-details-icon p-1 me-3 cp"
                                onClick={handleCopyClick}
                            >
                                <img src={share} />
                            </div>
                            {userData && userData?.role === 'customer' && (
                                <>
                                    {favourites.find(
                                        (item) =>
                                            item?.business?.id ===
                                            singleBusinessDetails?.id
                                    ) ? (
                                        <button
                                            disabled={isFavLoading}
                                            className=" service-details-icon p-1"
                                            onClick={() =>
                                                handleFavourite(
                                                    singleBusinessDetails.id,
                                                    'remove'
                                                )
                                            }
                                        >
                                            {isFavLoading ? (
                                                <Spin />
                                            ) : (
                                                <img src={heart} />
                                            )}
                                        </button>
                                    ) : (
                                        <button
                                            disabled={isFavLoading}
                                            className=" service-details-icon p-1"
                                            onClick={() =>
                                                handleFavourite(
                                                    singleBusinessDetails.id,
                                                    'add'
                                                )
                                            }
                                        >
                                            {isFavLoading ? (
                                                <Spin />
                                            ) : (
                                                <img src={outlineHeart} />
                                            )}
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <ImagesSection
                        businessDetails={singleBusinessDetails}
                        images={singleBusinessDetails?.pictures || []}
                    />
                    <div className="row">
                        <div className="col-md-8 cols-12">
                            <div class="services">
                                <ul class="nav nav-tabs" id="myTabs">
                                    <li class="nav-item">
                                        <a
                                            class="nav-link active"
                                            id="tab1-tab"
                                            data-bs-toggle="tab"
                                            href="#tab1"
                                        >
                                            Services
                                        </a>
                                    </li>
                                    {/* <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab2-tab"
                      data-bs-toggle="tab"
                      href="#tab2"
                    >
                      Team{" "}
                    </a>
                  </li> */}

                                    <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            id="tab4-tab"
                                            data-bs-toggle="tab"
                                            href="#tab4"
                                        >
                                            About{' '}
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            id="tab3-tab"
                                            data-bs-toggle="tab"
                                            href="#tab3"
                                        >
                                            Reviews{' '}
                                        </a>
                                    </li>
                                </ul>

                                <div class="tab-content">
                                    <div
                                        class="tab-pane fade show active py-3"
                                        id="tab1"
                                    >
                                        <Services
                                            businessDetails={
                                                singleBusinessDetails
                                            }
                                        />
                                    </div>
                                    {/* <div class="tab-pane fade py-3" id="tab2">
                    Team Section
                  </div> */}
                                    <div class="tab-pane fade py-3" id="tab3">
                                        <Reviews
                                            reviews={
                                                singleBusinessDetails?.reviews ||
                                                ''
                                            }
                                        />
                                    </div>
                                    <div class="tab-pane fade py-3" id="tab4">
                                        <About
                                            about={
                                                singleBusinessDetails?.about ||
                                                ''
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 cols-12 ">
                            <BookingMap
                                businessDetails={singleBusinessDetails}
                            />
                        </div>
                    </div>
                    <div className="row">
                        {singleBusinessDetails?.nearByBusinesses?.length >
                            0 && (
                            <div className=" cols-12">
                                <NearByYou
                                    data={
                                        singleBusinessDetails?.nearByBusinesses ||
                                        []
                                    }
                                />
                            </div>
                        )}
                    </div>
                    {/* <div className="d-md-none d-block">
            <BookingMap businessDetails={singleBusinessDetails} />
          </div> */}
                </div>
            )}
        </>
    );
};

export default ServiceDetails;

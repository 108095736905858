import React, { useEffect, useState } from 'react';
import ServiceHeader from './ServiceHeader';
import ServiceBookNow from './service-book-now';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const Services = ({ businessDetails, hide }) => {
    const [showServices, setShowServices] = useState(5);
    const [category, setCategory] = useState('');
    const [categoryData, setCategoryData] = useState(null);
    // Initialize expandedCategories with default open state
    const [expandedCategories, setExpandedCategories] = useState(() => {
        // If there's category data, pre-expand all subcategories
        const initialExpanded = {};
        if (categoryData) {
            Object.keys(categoryData)
                .filter((key) => key !== 'order')
                .forEach((key) => {
                    initialExpanded[key] = true;
                });
        }
        return initialExpanded;
    });

    const handleShowAll = () => {
        setShowServices(categoryData?.length);
    };

    const handleCategoryChange = (item) => {
        setCategory(item.category);
        setCategoryData(item?.services);
        // When changing category, expand all subcategories by default
        const initialExpanded = {};
        Object.keys(item?.services)
            .filter((key) => key !== 'order')
            .forEach((key) => {
                initialExpanded[key] = true;
            });
        setExpandedCategories(initialExpanded);
    };

    const toggleCategoryExpand = (subcategoryKey) => {
        setExpandedCategories((prev) => ({
            ...prev,
            [subcategoryKey]: !prev[subcategoryKey],
        }));
    };

    useEffect(() => {
        const firstCategory =
            businessDetails?.services &&
            Object.entries(businessDetails?.services)[0];
        if (firstCategory) {
            const [category, data] = firstCategory;
            setCategory(category);
            setCategoryData(data);

            // Automatically expand all subcategories
            const initialExpanded = {};
            Object.keys(data)
                .filter((key) => key !== 'order')
                .forEach((key) => {
                    initialExpanded[key] = true;
                });
            setExpandedCategories(initialExpanded);
        }
    }, [businessDetails]);

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <p className="f-36 mb-4 me-2 mt-3 poly">Services</p>{' '}
                {showServices < categoryData?.length && (
                    <p
                        className="d-block d-md-none m-0 c-3A475C cp fs-16 poppins"
                        onClick={handleShowAll}
                    >
                        <u>See More</u>
                    </p>
                )}
            </div>
            <ServiceHeader
                services={businessDetails?.services}
                handleCategoryChange={handleCategoryChange}
                category={category}
            />
            {categoryData &&
                Object.entries(categoryData)
                    .filter(([key]) => key !== 'order')
                    .map(([subcategoryKey, value]) => (
                        <div key={subcategoryKey} className="mb-3">
                            <div
                                className="d-flex justify-content-between align-items-center g-2 py-2 border-bottom"
                                onClick={() =>
                                    toggleCategoryExpand(subcategoryKey)
                                }
                            >
                                <h2 className="fs-5 mb-0">
                                    - {" "}{subcategoryKey}
                                </h2>
                                <div className="d-flex align-items-center g-2">
                                    <div className="services-header-item d-flex align-items-center">
                                        {value?.services?.length} Services
                                    </div>
                                    <button className="chevron_btn_cus ms-2">
                                        {expandedCategories[subcategoryKey] ? (
                                            <IoIosArrowUp />
                                        ) : (
                                            <IoIosArrowDown />
                                        )}
                                    </button>
                                </div>
                            </div>
                            {/* Services will be shown by default */}
                            {expandedCategories[subcategoryKey] &&
                                value?.services?.map((item, index) => (
                                    <ServiceBookNow
                                        item={item}
                                        id={
                                            hide
                                                ? businessDetails?.name
                                                : businessDetails?.id
                                        }
                                        key={index}
                                        hide={hide}
                                    />
                                ))}
                        </div>
                    ))}
        </div>
    );
};

export default Services;

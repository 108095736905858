/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './services.css';
import BreadCrum from '../breadcrum/BreadCrum';
import ServiceHeader from './ServiceHeader';
import SingleService from './single-service-card';
import ContinueCard from './continue-card';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
    onSelectedServicesChange,
    resetSelectedServices,
} from '../../redux/services/servicesActions';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const Service = ({ hide }) => {
    const dispatch = useDispatch();
    const { singleBusinessDetails, selectedServices } = useSelector(
        (state) => state.services
    );

    const [category, setCategory] = useState('');
    const [categoryData, setCategoryData] = useState([]);
    // New state to track expanded categories
    const [expandedCategories, setExpandedCategories] = useState({});

    const handleCategoryChange = (item) => {
        setCategory(item.category);
        setCategoryData(item.services);
        // Reset expanded categories when changing main category
        const initialExpanded = {};
        Object.keys(item?.services)
            .filter((key) => key !== 'order')
            .forEach((key) => {
                initialExpanded[key] = true;
            });
        setExpandedCategories(initialExpanded);
    };

    // Toggle function for expanding/collapsing service subcategories
    const toggleCategoryExpand = (subcategoryKey) => {
        setExpandedCategories((prev) => ({
            ...prev,
            [subcategoryKey]: !prev[subcategoryKey],
        }));
    };

    const handleSelectedServices = (item) => {
        let isAlready =
            selectedServices &&
            selectedServices?.find((ele) => ele.id == item.id);

        if (isAlready) {
            dispatch(
                onSelectedServicesChange(
                    selectedServices.filter((ele) => ele.id !== item.id)
                )
            );
        } else {
            dispatch(onSelectedServicesChange([...selectedServices, item]));
        }
    };

    useEffect(() => {
        const firstCategory =
            singleBusinessDetails?.services &&
            Object.entries(singleBusinessDetails?.services)[0];
        if (firstCategory) {
            const [category, data] = firstCategory;
            console.log(category, data);
            setCategory(category);
            setCategoryData(data);

            // Automatically expand all subcategories
            const initialExpanded = {};
            Object.keys(data)
                .filter((key) => key !== 'order')
                .forEach((key) => {
                    initialExpanded[key] = true;
                });
            setExpandedCategories(initialExpanded);
        }
    }, [singleBusinessDetails]);

    useEffect(() => {
        dispatch(resetSelectedServices());
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container mb-5">
            {!hide && (
                <BreadCrum
                    items={[
                        { title: 'Home', path: '/home' },
                        {
                            title: 'Business Details',
                            path: `/business-details/${singleBusinessDetails?.id}`,
                        },
                        {
                            title: 'Services',
                            path: `/business-details/${singleBusinessDetails?.id}/services`,
                        },
                    ]}
                />
            )}

            <div>
                <div className="row">
                    <div className="col-lg-8 col-md-7">
                        <ServiceHeader
                            services={singleBusinessDetails?.services}
                            handleCategoryChange={handleCategoryChange}
                            category={category}
                        />
                        {categoryData &&
                            Object.entries(categoryData)
                                .filter(([key]) => key !== 'order')
                                .map(([subcategoryKey, value]) => {
                                    return (
                                        <div
                                            className="mb-3"
                                            key={subcategoryKey}
                                        >
                                            <div
                                                className="d-flex justify-content-between align-items-center g-2 py-2 border-bottom"
                                                onClick={() =>
                                                    toggleCategoryExpand(
                                                        subcategoryKey
                                                    )
                                                }
                                            >
                                                <h2 className="fs-5 mb-0">
                                                    {subcategoryKey}
                                                </h2>
                                                <div className="d-flex align-items-center g-2">
                                                    <div className="services-header-item d-flex align-items-center">
                                                        {
                                                            value?.services
                                                                ?.length
                                                        }{' '}
                                                        Services
                                                    </div>
                                                    <button className="chevron_btn_cus ms-2">
                                                        {expandedCategories[
                                                            subcategoryKey
                                                        ] ? (
                                                            <IoIosArrowUp />
                                                        ) : (
                                                            <IoIosArrowDown />
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            {/* Conditionally render services based on expanded state */}
                                            {expandedCategories[
                                                subcategoryKey
                                            ] &&
                                                value?.services.map(
                                                    (item, index) => (
                                                        <SingleService
                                                            selectedServices={
                                                                selectedServices
                                                            }
                                                            handleSelectedServices={
                                                                handleSelectedServices
                                                            }
                                                            item={item}
                                                            key={index}
                                                        />
                                                    )
                                                )}
                                        </div>
                                    );
                                })}
                    </div>
                    <div className="col-lg-4 col-md-5">
                        <ContinueCard
                            selectedServices={selectedServices}
                            businessDetails={singleBusinessDetails}
                            hide={hide}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service;

export const GET_DETAILS = 'GET_DETAILS';
export const GET_PENDING = 'GET_PENDING';
export const GET_REJECTED = 'GET_REJECTED';
export const UPDATE_DETAILS = 'UPDATE_DETAILS';
export const UPDATE_REJECTED = 'UPDATE_REJECTED';
export const UPDATE_PENDING = 'UPDATE_PENDING';
export const INSTA_RES = 'INSTA_RES';
export const RESET_INSTA_FILES = 'RESET_INSTA_FILES';

export const UPDATE_AUTOMATED_SETTING_START = 'UPDATE_AUTOMATED_SETTING_START';
export const UPDATE_AUTOMATED_SETTING_SUCCESS =
    'UPDATE_AUTOMATED_SETTING_SUCCESS';
export const UPDATE_AUTOMATED_SETTING_ERROR = 'UPDATE_AUTOMATED_SETTING_ERROR';

export const GET_AUTOMATED_SETTING_START = 'GET_AUTOMATED_SETTING_START';
export const GET_AUTOMATED_SETTING_SUCCESS = 'GET_AUTOMATED_SETTING_SUCCESS';
export const GET_AUTOMATED_SETTING_ERROR = 'GET_AUTOMATED_SETTING_ERROR';
export const GET_PRICING_START = 'GET_PRICING_START';
export const GET_PRICING_SUCCESS = 'GET_PRICING_SUCCESS';
export const GET_PRICING_ERROR = 'GET_PRICING_ERROR';

export const GET_COUNTRY_SPEC_PENDING = 'GET_COUNTRY_SPEC_PENDING';
export const GET_COUNTRY_SPEC_SUCCESS = 'GET_COUNTRY_SPEC_SUCCESS';
export const GET_COUNTRY_SPEC_REJECTED = 'GET_COUNTRY_SPEC_REJECTED';

export const CONNECT_ACCOUNT_PENDING = 'CONNECT_ACCOUNT_PENDING';
export const CONNECT_ACCOUNT_SUCCESS = 'CONNECT_ACCOUNT_SUCCESS';
export const CONNECT_ACCOUNT_REJECTED = 'CONNECT_ACCOUNT_REJECTED';

export const CONNECT_ACCOUNT_STATUS_PENDING = 'CONNECT_ACCOUNT_STATUS_PENDING';
export const CONNECT_ACCOUNT_STATUS_SUCCESS = 'CONNECT_ACCOUNT_STATUS_SUCCESS';
export const CONNECT_ACCOUNT_STATUS_REJECTED =
    'CONNECT_ACCOUNT_STATUS_REJECTED';

export const GET_CONNECT_ACCOUNT_PENDING = 'GET_CONNECT_ACCOUNT_PENDING';
export const GET_CONNECT_ACCOUNT_SUCCESS = 'GET_CONNECT_ACCOUNT_SUCCESS';
export const GET_CONNECT_ACCOUNT_REJECTED = 'GET_CONNECT_ACCOUNT_REJECTED';

import moment from 'moment';
import actions from './actions';
import { axiosInstance } from '../../repository/Repository';
import { toast } from 'react-toastify';
import { getDetails } from '../businessAccount/detailsAction';

const initialState = [
    {
        title: 'Family Events',
        date: [moment().format('MM/DD/YYYY'), moment().format('MM/DD/YYYY')],
        id: 1,
        label: 'success',
        time: ['2:00 pm', '3:00 pm'],
        type: 'event',
        description:
            'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    },
    {
        title: 'Product Launch',
        date: [
            moment().format('MM/DD/YYYY'),
            moment(moment().add(2, 'days')).format('MM/DD/YYYY'),
        ],
        id: 2,
        label: 'primary',
        time: ['4:00 pm', '5:00 pm'],
        type: 'reminder',
        description:
            'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    },
    {
        title: 'Team Meeting',
        id: 3,
        date: [
            moment(moment().subtract(2, 'days')).format('MM/DD/YYYY'),
            moment(moment().subtract(2, 'days')).format('MM/DD/YYYY'),
        ],
        label: 'secondary',
        time: ['2:00 pm', '3:00 pm'],
        type: 'event',
        description:
            'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    },
    {
        title: 'UI/UX Tasks',
        id: 4,
        date: ['09/12/2020', '09/12/2020'],
        label: 'info',
        time: ['9:00 am', '3:00 pm'],
        type: 'event',
        description:
            'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    },
    {
        title: 'Project Update',
        id: 5,
        date: [
            moment(moment().add(5, 'days')).format('MM/DD/YYYY'),
            moment(moment().add(5, 'days')).format('MM/DD/YYYY'),
        ],
        label: 'warning',
        time: ['12:00 pm', '2:30 pm'],
        type: 'event',
        description:
            'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    },
];

const {
    calendarReadBegin,
    calendarReadSuccess,
    calendarReadErr,
    eventVisibleBegin,
    eventVisibleSuccess,
    eventVisibleErr,
    starUpdateBegin,
    starUpdateSuccess,
    starUpdateErr,
    labelUpdateBegin,
    labelUpdateSuccess,
    labelUpdateErr,
    updateStatus,
    isBlockLoading,
    isDateBlockLoading,
} = actions;

const addNewEvents = (events) => {
    return async (dispatch) => {
        try {
            dispatch(calendarReadBegin());
            dispatch(calendarReadSuccess(events));
        } catch (err) {
            dispatch(calendarReadErr(err));
        }
    };
};

const updateCurrentEvent = (events, event, id) => {
    return async (dispatch) => {
        try {
            dispatch(calendarReadBegin());
            events.map((value) => {
                const item = value;
                if (item.id === id) {
                    item.title = event.title;
                    item.description = event.description;
                    item.date = event.date;
                    item.time = event.time;
                    item.label = event.label;
                    item.type = event.type;
                }
                return item;
            });
            // console.log(events);
            dispatch(calendarReadSuccess(events));
        } catch (err) {
            dispatch(calendarReadErr(err));
        }
    };
};

const eventVisible = (data) => {
    return async (dispatch) => {
        try {
            dispatch(eventVisibleBegin());
            dispatch(eventVisibleSuccess(data));
        } catch (err) {
            dispatch(eventVisibleErr(err));
        }
    };
};

const calendarAddData = (data) => {
    return async (dispatch) => {
        try {
            dispatch(calendarReadBegin());
            dispatch(calendarReadSuccess(data));
        } catch (err) {
            dispatch(calendarReadErr(err));
        }
    };
};

const calendarDeleteData = (data) => {
    return async (dispatch) => {
        try {
            dispatch(calendarReadBegin());
            dispatch(calendarReadSuccess(data));
        } catch (err) {
            dispatch(calendarReadErr(err));
        }
    };
};

const onStarUpdate = (data, id) => {
    return async (dispatch) => {
        try {
            dispatch(starUpdateBegin());
            data.map((item) => {
                if (item.key === id) {
                    const fav = item;
                    if (item.stared) {
                        fav.stared = false;
                    } else {
                        fav.stared = true;
                    }
                }
                return dispatch(starUpdateSuccess(data));
            });
        } catch (err) {
            dispatch(starUpdateErr(err));
        }
    };
};

const onLabelUpdate = (data, id, value) => {
    return async (dispatch) => {
        try {
            dispatch(labelUpdateBegin());
            data.map((item) => {
                if (item.key === id) {
                    const fav = item;
                    fav.label = value;
                }
                return dispatch(labelUpdateSuccess(data));
            });
        } catch (err) {
            dispatch(labelUpdateErr(err));
        }
    };
};

const onLabelFilter = (label) => {
    return async (dispatch) => {
        try {
            dispatch(labelUpdateBegin());
            const data = initialState.filter((item) => {
                return label === 'all'
                    ? initialState
                    : label === 'favorite'
                    ? item.stared
                    : item.label === label;
            });

            dispatch(labelUpdateSuccess(data));
        } catch (err) {
            dispatch(labelUpdateErr(err));
        }
    };
};

// =============GET ALL BUSINESS APPOINTMENT=================
export const getAllBusinessAppointments = () => async (dispatch) => {
    dispatch(calendarReadBegin());
    try {
        const response = await axiosInstance.get('/appointments/all');
        const appointments = response.data?.data[0];

        const sampleData = appointments.map((appointment) => {
            const services = appointment.appointmentService.map(
                (service) => service?.service
            );
            return {
                title: appointment?.user?.name || appointment?.customerName,
                services,
                date: appointment?.date,
                id: appointment?.id,
                label: appointment?.status,
                time: appointment?.time,
                type: 'appointment',
                description: appointment?.notes,
                customerUser: appointment.user,
            };
        });
        dispatch(calendarReadSuccess(sampleData));
    } catch (error) {
        dispatch(calendarReadErr(error));
        console.error('There was an error! please try again later');
    }
};

// =============HANDLE APPOINTMENT STATUS=================
export const handleAppointmentStatus =
    (status, id, onSuccess = () => {}) =>
    async (dispatch) => {
        try {
            dispatch(updateStatus(true));
            await axiosInstance.put(`/appointments/${id}`, { status });
            await dispatch(getAllBusinessAppointments());
            dispatch(updateStatus(false));
            toast.success(`Appointment ${status} successfully`);
            onSuccess();
        } catch (error) {
            dispatch(updateStatus(false));
            toast.error(
                error?.data?.message ||
                    'There was an error! please try again later'
            );
            console.error(error);
        }
    };

export const handleCancelAppointment =
    (status, id, onSuccess = () => {}) =>
    async (dispatch) => {
        try {
            dispatch(updateStatus(true));
            await axiosInstance.put(`/appointments/reject/${id}`);
            await dispatch(getAllBusinessAppointments());
            dispatch(updateStatus(false));
            toast.success(`Appointment "${status}" successfully`);
            onSuccess();
        } catch (error) {
            dispatch(updateStatus(false));
            toast.error(
                error?.data?.message ||
                    'There was an error! please try again later'
            );
            console.error(error);
        }
    };

export const handleBlockStatus =
    (data, onSuccess = () => {}) =>
    async (dispatch) => {
        try {
            data?.blockType === 'indefinite'
                ? dispatch(isBlockLoading(true))
                : dispatch(updateStatus(true));
            await axiosInstance.put(`/business/block-service`, data);
            await dispatch(getDetails());
            data?.blockType === 'indefinite'
                ? dispatch(isBlockLoading(false))
                : dispatch(updateStatus(false));
            toast.success('Block dates added');
            onSuccess();
        } catch (error) {
            data?.blockType === 'indefinite'
                ? dispatch(isBlockLoading(false))
                : dispatch(updateStatus(false));
            toast.error(
                error?.data?.message ||
                    'There was an error! please try again later'
            );
            console.error(error);
        }
    };
export const handleBlockStatusReset =
    (onSuccess = () => {}) =>
    async (dispatch) => {
        try {
            dispatch(isBlockLoading(true));
            await axiosInstance.put(`/business/reset-block-status`);
            await dispatch(getDetails());
            dispatch(isBlockLoading(false));
            toast.success('Block dates reset');
            onSuccess();
        } catch (error) {
            dispatch(isBlockLoading(false));
            toast.error(
                error?.data?.message ||
                    'There was an error! please try again later'
            );
            console.error(error);
        }
    };

export const handleBlockSpecificDateSlots =
    (data, onSuccess = () => {}) =>
        async (dispatch) => {
        try {
            dispatch(isDateBlockLoading(true));
            await axiosInstance.put(
                `/business/update-block-dates/${data?.id}`,
                data?.data
            );
            await dispatch(getDetails());
            toast.success('Block dates added');
            dispatch(isDateBlockLoading(false));
            onSuccess();
        } catch (error) {
            toast.error(
                error?.data?.message ||
                    'There was an error! please try again later'
            );
            console.error(error);
            dispatch(isDateBlockLoading(false));
        }
    };

export const addAppointmentManually =
    (data, onSuccess = () => {}) =>
    async (dispatch) => {
        try {
            dispatch(updateStatus(true));
            await axiosInstance.post(`/appointments/manual/add`, data);
            await dispatch(getAllBusinessAppointments());
            dispatch(updateStatus(false));
            toast.success('Appointment created successfully');
            onSuccess();
        } catch (error) {
            dispatch(updateStatus(false));
            toast.error(
                error?.data?.message ||
                    'There was an error! please try again later'
            );
            console.error(error);
        }
    };

export {
    eventVisible,
    calendarAddData,
    calendarDeleteData,
    onStarUpdate,
    onLabelUpdate,
    onLabelFilter,
    addNewEvents,
    updateCurrentEvent,
};

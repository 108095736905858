import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { urlRegex } from '../../helpers/constants';
import { fetchAllBusinessNames } from '../../redux/services/servicesActions';
import { useDispatch } from 'react-redux';

const Business = ({ handleNext, handleChange, formData, message }) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const { businessNames } = useSelector((state) => state.services);

    const normalize = (str) => str.toLowerCase().replace(/\s+/g, '-');

    const handleNextClick = () => {
        let newErrors = {};

        const normalizedInputName = normalize(formData.name);
        const normalizedBusinessNames = businessNames.map(normalize);

        if (!formData.name) {
            newErrors.name = 'Business name should not be empty';
        } else if (normalizedBusinessNames.includes(normalizedInputName)) {
            newErrors.name = 'This business name is already taken';
        }

        if (formData.website && !urlRegex.test(formData.website)) {
            newErrors.website =
                "Provide a valid URL 'https://www.yourwebsite.com'";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            handleNext();
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        handleChange(e);

        if (name === 'name') {
            const normalizedInputName = normalize(value);
            const normalizedBusinessNames = businessNames.map(normalize);

            if (!value.trim()) {
                setErrors((prev) => ({
                    ...prev,
                    name: 'Business name should not be empty',
                }));
            } else if (normalizedBusinessNames.includes(normalizedInputName)) {
                setErrors((prev) => ({
                    ...prev,
                    name: 'This business name is already taken',
                }));
            } else {
                setErrors((prev) => ({ ...prev, name: '' }));
            }
        }
    };

    useEffect(() => {
        dispatch(fetchAllBusinessNames());
    }, []);

    return (
        <div className={`container-fluid max-w-md-605`}>
            <div className={`row justify-content-center pb-md-40 pb-3`}>
                <div className={`col-md-12 px-0 text-center pt-4 pb-16`}>
                    <h5 className={`m-0 c-334155 poly f-36px fw-normal lh-48`}>
                        What’s your business name?
                    </h5>
                </div>
                <div className={`col-md-8 col-12 px-0 text-center lh-0`}>
                    <span
                        className={`m-0 c-667085 poppins f-14 fw-normal lh-20`}
                    >
                        This is the brand name your client will see. Your
                        billing and legal name can be added later.
                    </span>
                </div>
            </div>
            {/*{message &&*/}
            {/*    <div className={`centered-container`}>*/}
            {/*        <div className={`success-badge mb-2`}>*/}
            {/*            <span>{message}!</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
            <div className={`row`}>
                <div className={`col-12 px-md-0`}>
                    <div className={`card-step`}>
                        <div className={`row pb-md-40 pb-3`}>
                            <div
                                className={`col-md-12 pb-20 position-relative`}
                            >
                                <label
                                    htmlFor="businessName"
                                    className="form-label c-667085 poppins f-14 fw-400 lh-20"
                                >
                                    Business name{' '}
                                    <span className={`text-danger`}>*</span>
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className="form-control input-filed"
                                    id="businessName"
                                    placeholder="Enter your Business name"
                                />
                                {errors.name && (
                                    <p className={`password-error-text`}>
                                        {errors.name}
                                    </p>
                                )}
                            </div>
                            <div
                                className={`col-md-12 pb-20 position-relative`}
                            >
                                <label
                                    htmlFor="website"
                                    className="form-label c-667085 poppins f-14 fw-400 lh-20"
                                >
                                    Website
                                </label>
                                <input
                                    type="text"
                                    className="form-control input-filed"
                                    name="website"
                                    value={formData.website}
                                    onChange={handleChange}
                                    id="website"
                                    placeholder="https://www.yourwebsite.com"
                                />
                                {errors.website && (
                                    <p className={`password-error-text`}>
                                        {errors.website}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className={`row`}>
                            <div
                                className={`col-md-12 pl-md-7-5 pb-md-0 pb-3 order-md-1 order-0`}
                                onClick={handleNextClick}
                            >
                                <button className={`btn btn-next`}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Business;

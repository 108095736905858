import { toast } from 'react-toastify';
import {
    ADD_FAV_ERROR,
    ADD_FAV_START,
    ADD_FAV_SUCCESS,
    DELETE_SERVICE_REJECTED,
    DELETE_SERVICE_START,
    DELETE_SERVICE_SUCCESS,
    DELETE_SUB_CATEGORY,
    EXTRA_FIELD_CHANGE,
    FETCH_ALL_BUSINESS_NAMES_ERROR,
    FETCH_ALL_BUSINESS_NAMES_START,
    FETCH_ALL_BUSINESS_NAMES_SUCCESS,
    FETCH_LATEST_BUSINESS_ERROR,
    FETCH_LATEST_BUSINESS_START,
    FETCH_LATEST_BUSINESS_SUCCESS,
    FETCH_RECOMEND_BUSINESS_ERROR,
    FETCH_RECOMEND_BUSINESS_START,
    FETCH_RECOMEND_BUSINESS_SUCCESS,
    FETCH_SERVICES_SUCCESS,
    FETCH_SINGLE_BUSINESS_REJECTED,
    FETCH_SINGLE_BUSINESS_START,
    FETCH_SINGLE_BUSINESS_SUCCESS,
    FILTER_VALUE_CHANGE,
    FULFILLED,
    GET_ALL_FAV_ERROR,
    GET_ALL_FAV_START,
    GET_ALL_FAV_SUCCESS,
    GET_ALL_SUB_CATEGORY_ERROR,
    GET_ALL_SUB_CATEGORY_PENDING,
    GET_ALL_SUB_CATEGORY_SUCCESS,
    LOAD_MORE_ERROR,
    LOAD_MORE_START,
    LOAD_MORE_SUCCESS,
    PENDING,
    REJECTED,
    REORDER_MAIN_SERVICES_ERROR,
    REORDER_MAIN_SERVICES_START,
    REORDER_MAIN_SERVICES_SUCCESS,
    RESET_FILTER_VALUES,
    RESET_LOAD_MORE,
    SEARCH_SERVICES_REJECTED,
    SEARCH_SERVICES_START,
    SEARCH_SERVICES_SUCCESS,
    SELECTED_SERVICES_CHANGE,
    SELECTED_SERVICES_RESET,
    UPDATE_SUB_CATEGORY,
} from './servicesTypes';
import { axiosInstance } from '../../repository/Repository';
import { getDetails } from '../businessAccount/detailsAction';

export const createService =
    (data, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: PENDING });

        try {
            const response = await axiosInstance.post('/service/add', data);
            console.log(response);
            dispatch({ type: FULFILLED });
            toast.success('Service Created Successfully');
            onSuccess();
            await dispatch(getDetails());
        } catch (error) {
            toast.error('There was an error! please try again later');
            dispatch({ type: REJECTED, payload: error });
        }
    };

export const getMyServices =
    (limit, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: PENDING });
        try {
            const response = await axiosInstance.get(
                `/service/my-services?limit=${limit}`
            );
            let payload = response.data;
            dispatch({ type: FETCH_SERVICES_SUCCESS, payload: payload });
            onSuccess();
        } catch (error) {
            dispatch({ type: REJECTED, payload: error });
        }
    };

export const reorderBusinessMainServices =
    (data, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: REORDER_MAIN_SERVICES_START });
        try {
            await axiosInstance.put(`/business/change-order`, data);
            dispatch({ type: REORDER_MAIN_SERVICES_SUCCESS });
            onSuccess();
        } catch (error) {
            dispatch({ type: REORDER_MAIN_SERVICES_ERROR, payload: error });
        }
    };
export const reorderBusinessSubcategory =
    (data, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: REORDER_MAIN_SERVICES_START });
        try {
            await axiosInstance.put(`/business-subcategory/change-order`, data);
            dispatch({ type: REORDER_MAIN_SERVICES_SUCCESS });
            onSuccess();
        } catch (error) {
            dispatch({ type: REORDER_MAIN_SERVICES_ERROR, payload: error });
        }
    };

export const reorderBusinessServices =
    (data, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: REORDER_MAIN_SERVICES_START });
        try {
            await axiosInstance.put(`/service/change-order`, data);
            dispatch({ type: REORDER_MAIN_SERVICES_SUCCESS });
            onSuccess();
        } catch (error) {
            dispatch({ type: REORDER_MAIN_SERVICES_ERROR, payload: error });
        }
    };

export const updateService =
    (data, id, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: PENDING });
        try {
            await axiosInstance.put(`/service/${id}`, data);
            dispatch({ type: FULFILLED });
            toast.success('Service Updated Successfully');
            onSuccess();
        } catch (error) {
            console.log(error);
            toast.error(error?.data?.message);
            dispatch({ type: REJECTED, payload: error });
        }
    };

export const deleteService =
    (id, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: DELETE_SERVICE_START });
        try {
            await axiosInstance.delete(`/service/${id}`);
            dispatch({ type: DELETE_SERVICE_SUCCESS });
            toast.success('Service Deleted Successfully');
            onSuccess();
        } catch (error) {
            toast.error(error?.data?.message);
            dispatch({ type: DELETE_SERVICE_REJECTED, payload: error });
        }
    };

// =========SEARCH SERVICES===========
export const searchServices =
    (data, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: SEARCH_SERVICES_START });
        dispatch({ type: RESET_LOAD_MORE });
        try {
            const response = await axiosInstance.post(`/service/search`, data);
            dispatch({
                type: SEARCH_SERVICES_SUCCESS,
                payload: {
                    data: response?.data?.data[0]?.businesses,
                    pagination: response?.data?.data[0]?.pagination,
                },
            });
            onSuccess();
        } catch (error) {
            dispatch({ type: SEARCH_SERVICES_REJECTED, payload: error });
        }
    };
export const loadMoreServices =
    (data, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: LOAD_MORE_START });
        try {
            const response = await axiosInstance.post(`/service/search`, data);
            dispatch({
                type: LOAD_MORE_SUCCESS,
                payload: {
                    data: response?.data?.data[0]?.businesses,
                    pagination: response?.data?.data[0]?.pagination,
                },
            });
            onSuccess();
        } catch (error) {
            dispatch({ type: LOAD_MORE_ERROR, payload: error });
        }
    };

// =========GET SINGLE BUSINESS DETAILS===========
export const getSingleBusiness =
    (id, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: FETCH_SINGLE_BUSINESS_START });
        try {
            const response = await axiosInstance.get(`/business/${id}`);
            console.log(response);
            let payload = response.data.data[0];
            dispatch({ type: FETCH_SINGLE_BUSINESS_SUCCESS, payload });
            onSuccess();
        } catch (error) {
            dispatch({ type: FETCH_SINGLE_BUSINESS_REJECTED });
        }
    };

export const getSingleBusinessByName =
    (name, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: FETCH_SINGLE_BUSINESS_START });
        try {
            const response = await axiosInstance.get(`business/search/${name}`);
            let payload = response?.data;
            dispatch({ type: FETCH_SINGLE_BUSINESS_SUCCESS, payload });
            onSuccess();
        } catch (error) {
            dispatch({ type: FETCH_SINGLE_BUSINESS_REJECTED });
        }
    };
// =========GET LATEST BUSINESSES===========
export const fetchLatestBusinesses = () => async (dispatch) => {
    dispatch({ type: FETCH_LATEST_BUSINESS_START });
    try {
        const response = await axiosInstance.get(`/business/new`);
        console.log(response);
        let payload = response.data;
        dispatch({ type: FETCH_LATEST_BUSINESS_SUCCESS, payload });
    } catch (error) {
        dispatch({ type: FETCH_LATEST_BUSINESS_ERROR });
        throw error;
    }
};

// =========GET RECOMENDED BUSINESSES===========
export const fetchRecomendedBusinesses = () => async (dispatch) => {
    dispatch({ type: FETCH_RECOMEND_BUSINESS_START });
    try {
        const response = await axiosInstance.get(`/business/recomended`);
        let payload = response.data;
        dispatch({ type: FETCH_RECOMEND_BUSINESS_SUCCESS, payload });
    } catch (error) {
        dispatch({ type: FETCH_RECOMEND_BUSINESS_ERROR });
        throw error;
    }
};

// =========GET ALL FAV===========
export const fetchAllFav = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_FAV_START });
        const res = await axiosInstance.get(`/favourites/all`);
        let payload = res.data;
        dispatch({ type: GET_ALL_FAV_SUCCESS, payload });
    } catch (error) {
        dispatch({ type: GET_ALL_FAV_ERROR });
        throw error;
    }
};

// =========ADD TO FAV===========
export const addToFavourite = (data) => async (dispatch) => {
    dispatch({ type: ADD_FAV_START });
    try {
        await axiosInstance.post(`/favourites/add`, data);
        await dispatch(fetchAllFav());
        toast.success('Successfully added to your favorites list.');
        dispatch({ type: ADD_FAV_SUCCESS });
    } catch (error) {
        dispatch({ type: ADD_FAV_ERROR });
        throw error;
    }
};

// =========REMOVE FROM FAV===========
export const removeFromFav = (id) => async (dispatch) => {
    dispatch({ type: ADD_FAV_START });
    try {
        await axiosInstance.delete(`/favourites/remove/${id}`);
        await dispatch(fetchAllFav());
        toast.success('Successfully removed from your favorites list.');
        dispatch({ type: ADD_FAV_SUCCESS });
    } catch (error) {
        dispatch({ type: ADD_FAV_ERROR });
        throw error;
    }
};

//Fetch all business names
export const fetchAllBusinessNames = () => async (dispatch) => {
    dispatch({ type: FETCH_ALL_BUSINESS_NAMES_START });
    try {
        const response = await axiosInstance.get(`/business/names`);
        let payload = response.data;
        dispatch({ type: FETCH_ALL_BUSINESS_NAMES_SUCCESS, payload });
    } catch (error) {
        dispatch({ type: FETCH_ALL_BUSINESS_NAMES_ERROR });
        throw error;
    }
};

export const updateFilteredValues = (filteredValues) => ({
    type: FILTER_VALUE_CHANGE,
    payload: filteredValues,
});

export const updateExtraFieldValues = (val) => ({
    type: EXTRA_FIELD_CHANGE,
    payload: val,
});

export const onSelectedServicesChange = (val) => ({
    type: SELECTED_SERVICES_CHANGE,
    payload: val,
});

export const resetSelectedServices = () => ({
    type: SELECTED_SERVICES_RESET,
});

export const resetFilterValues = () => ({
    type: RESET_FILTER_VALUES,
});

// =============SUB CATEGORIES=================
// =========GET ALL SUB CAT===========
export const getAllSubCategories = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_SUB_CATEGORY_PENDING });
        const res = await axiosInstance.get(
            `/business-subcategory/business/${id}`
        );
        let payload = res.data;
        dispatch({ type: GET_ALL_SUB_CATEGORY_SUCCESS, payload });
    } catch (error) {
        dispatch({ type: GET_ALL_SUB_CATEGORY_ERROR });
        throw error;
    }
};

export const getAllSubCategoriesSeamless = () => async (dispatch) => {
    try {
        const res = await axiosInstance.get(`/business-subcategory`);
        let payload = res.data;
        dispatch({ type: GET_ALL_SUB_CATEGORY_SUCCESS, payload });
    } catch (error) {
        dispatch({ type: GET_ALL_SUB_CATEGORY_ERROR });
        throw error;
    }
};

// =========UPDATE CATEGORY===========
export const createSubCategory = (data, onSuccess) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_SUB_CATEGORY, payload: true });
        const res = await axiosInstance.post(`/business-subcategory`, data);
        await dispatch(getAllSubCategoriesSeamless());
        onSuccess(res.data);
        dispatch({ type: UPDATE_SUB_CATEGORY, payload: false });
    } catch (error) {
        dispatch({ type: UPDATE_SUB_CATEGORY, payload: false });
        throw error;
    }
};
// =========UPDATE CATEGORY===========
export const updateSubCategory = (data, onSuccess) => async (dispatch) => {
    try {
        const { id, ...rest } = data;
        dispatch({ type: UPDATE_SUB_CATEGORY, payload: true });
        await axiosInstance.put(`/business-subcategory/${id}`, rest);
        await dispatch(getAllSubCategoriesSeamless());
        onSuccess();
        dispatch({ type: UPDATE_SUB_CATEGORY, payload: false });
    } catch (error) {
        dispatch({ type: UPDATE_SUB_CATEGORY, payload: false });
        throw error;
    }
};
// =========DELETE CATEGORY===========
export const deleteSubCategory = (id, onSuccess) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_SUB_CATEGORY, payload: true });
        await axiosInstance.delete(`/business-subcategory/${id}`);
        await dispatch(getAllSubCategoriesSeamless());
        onSuccess();
        dispatch({ type: DELETE_SUB_CATEGORY, payload: false });
    } catch (error) {
        dispatch({ type: DELETE_SUB_CATEGORY, payload: false });
        throw error;
    }
};

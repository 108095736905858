import React from 'react';
import BreadCrum from '../breadcrum/BreadCrum';
import SelectTime from './SelectTime';
import PaymentMethod from '../../appointments/PaymentMethod';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import SelectTimeContinue from './SelectTimeContinue';
import { useEffect } from 'react';
import { monthNames } from '../../helpers/constants';
import { useDispatch } from 'react-redux';
import { createAppointment } from '../../redux/appointments/appointmentAction';
import { useHistory } from 'react-router-dom';
import { getCustomerDetails } from '../../redux/users/actionCreator';

const Appointment = ({ hide }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { userData } = useSelector((state) => state.user);
    const { singleBusinessDetails, selectedServices, filteredValues } =
        useSelector((state) => state.services);
    const [activeTab, setActiveTab] = useState('time');
    const [selectedSlots, setSelectedSlots] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [stripeToken, setStripeToken] = useState('');
    const [paymentMethodId, setPaymentMethodId] = useState('');
    const [getToken, setGetToken] = useState(false);
    const [cardId, setCardId] = useState('');
    const [notes, setNotes] = useState('');
    const [phone, setPhone] = useState('');
    const [countryCode, setCountryCode] = useState('+44');
    const [currentDay] = useState(new Date());

    const bookAppointment = () => {
        setGetToken(true);
    };

    const handleBooking = () => {
        const { date, month, year } = selectedDate;
        const monthNumber = monthNames.indexOf(month) + 1;
        let formattedDate = `${year}-${monthNumber}-${date}`;
        let time = selectedSlots.split('-')[0];
        let serviceId = selectedServices.map((item) => item.id);
        let business = singleBusinessDetails?.id;
        let phoneNumber = countryCode + '-' + phone;
        let amount = selectedServices.reduce((total, service) => {
            return total + Number(service.price);
        }, 0);

        let payload = {
            date: formattedDate,
            time,
            serviceId,
            business,
            token: stripeToken,
            notes,
            phone: phoneNumber,
            amount,
            cardId,
            paymentMethodId,
            email: userData?.email,
        };
        dispatch(
            createAppointment(
                payload,
                () => {
                    history.push('/customer/upcoming-appointments');
                },
                () => {
                    setPaymentMethodId('');
                    setStripeToken('');
                }
            )
        );
    };

    useEffect(() => {
        if (stripeToken) {
            handleBooking();
        }
    }, [stripeToken]);

    // useEffect(() => {
    //   if (filteredValues.date === "weekdays") {
    //     const today = moment();
    //     const weekday = today.isoWeekday();

    //     // If today is a Saturday or Sunday, select the closest Monday
    //     if (weekday === 6 || weekday === 7) {
    //       setSelectedDate({
    //         date: today.startOf("week").add(1, "day").date(),
    //         day: "Mon",
    //         isSelected: true,
    //         month: today.format("MMMM"),
    //         year: today.year(),
    //       });
    //     } else {
    //       setSelectedDate({
    //         date: today.date(),
    //         day: today.format("ddd"),
    //         isSelected: true,
    //         month: today.format("MMMM"),
    //         year: today.year(),
    //       });
    //     }
    //   } else if (filteredValues.date === "weekends") {
    //     const today = moment();
    //     const weekday = today.isoWeekday();

    //     // If today is Saturday or Sunday, select today, otherwise select the next Saturday
    //     if (weekday === 6 || weekday === 7) {
    //       setSelectedDate({
    //         date: today.date(),
    //         day: today.format("ddd"),
    //         isSelected: true,
    //         month: today.format("MMMM"),
    //         year: today.year(),
    //       });
    //     } else {
    //       setSelectedDate({
    //         date: today.endOf("week").day(6).date(),
    //         day: "Sat",
    //         isSelected: true,
    //         month: today.format("MMMM"),
    //         year: today.year(),
    //       });
    //     }
    //   } else if (filteredValues.date === "All Days") {
    //     const today = moment();
    //     setSelectedDate({
    //       date: today.date(),
    //       day: today.format("ddd"),
    //       isSelected: true,
    //       month: today.format("MMMM"),
    //       year: today.year(),
    //     });
    //   } else {
    //     const parsedDate = moment(filteredValues.date, "DD/MM/YYYY");
    //     const formattedDate = {
    //       date: parsedDate.date(),
    //       day: parsedDate.format("ddd"),
    //       isSelected: true,
    //       month: parsedDate.format("MMMM"),
    //       year: parsedDate.year(),
    //     };
    //     setSelectedDate(formattedDate);
    //   }
    //   window.scrollTo(0, 0);
    // }, []);

    useEffect(() => {
        dispatch(getCustomerDetails());
    }, []);

    return (
        <div className="container mb-5 mt-4">
            {!hide && (
                <BreadCrum
                    items={
                        activeTab === 'time'
                            ? [
                                  { title: 'Home', path: '/home' },
                                  {
                                      title: 'Business Details',
                                      path: `/business-details/${singleBusinessDetails?.id}`,
                                  },
                                  {
                                      title: 'Services',
                                      path: `/business-details/${singleBusinessDetails?.id}/services`,
                                  },
                                  {
                                      title: 'Select Time',
                                      path: `/business-details/${singleBusinessDetails?.id}/services/book-appointment`,
                                  },
                              ]
                            : [
                                  { title: 'Home', path: '/home' },
                                  {
                                      title: 'Business Details',
                                      path: `/business-details/${singleBusinessDetails?.id}`,
                                  },
                                  {
                                      title: 'Services',
                                      path: `/business-details/${singleBusinessDetails?.id}/services`,
                                  },
                                  {
                                      title: 'Select Time',
                                      path: `/business-details/${singleBusinessDetails?.id}/services/book-appointment`,
                                      action: () => setActiveTab('time'),
                                  },
                                  {
                                      title: 'Confirm',
                                      path: '/business-details/123/services/book-appointment',
                                  },
                              ]
                    }
                />
            )}
            <div className="row">
                <div className="col-lg-8 col-md-7">
                    {activeTab === 'time' ? (
                        <SelectTime
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            selectedSlots={selectedSlots}
                            setSelectedSlots={setSelectedSlots}
                        />
                    ) : (
                        <PaymentMethod
                            getToken={getToken}
                            setGetToken={setGetToken}
                            setStripeToken={setStripeToken}
                            notes={notes}
                            setNotes={setNotes}
                            cardId={cardId}
                            setCardId={setCardId}
                            setPaymentMethodId={setPaymentMethodId}
                        />
                    )}
                </div>
                <div className="col-lg-4 col-md-5">
                    <SelectTimeContinue
                        selectedDate={selectedDate}
                        selectedSlots={selectedSlots}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        currentDay={currentDay}
                        bookAppointment={bookAppointment}
                        phone={phone}
                        setPhone={setPhone}
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                        handleBooking={handleBooking}
                        cardId={cardId}
                    />
                </div>
            </div>
        </div>
    );
};

export default Appointment;
